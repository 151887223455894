import { Global, css } from "@emotion/react";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      ::-webkit-file-upload-button {
        display: none;
      }

      ::file-selector-button {
        display: none;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }

      .daterangepicker {
        margin: 0;
        font-size: 14px;
        font-family: SofiaProLight, SofiaProMedium, SofiaProSemiBold, SofiaPro,
          Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
        line-height: 1.5;
        color: #0d47a1;
        width: 690px !important;
        margin-right: 47px;
      }

      .daterangepicker::after,
      .daterangepicker::before {
        display: none !important;
      }

      .daterangepicker .drp-calendar.left {
        padding: 25px 0 0 !important;
        margin-left: 55px;
      }

      .daterangepicker .drp-calendar.right {
        padding: 25px 0 0 !important;
        margin-right: 55px;
        float: right;
      }

      .daterangepicker .calendar-table thead tr:nth-of-type(2) th {
        margin: 0;
        font-family: SofiaProSemiBold;
        line-height: 1.25;
        font-weight: 500;
        color: #0d47a1;
        font-size: 15px;
      }

      .daterangepicker .calendar-table td {
        height: 36px !important;
      }

      .daterangepicker .calendar-table thead tr td {
        font-family: "SofiaProLight";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        text-align: center;
        color: #0d529d;
      }

      .daterangepicker .start-date,
      .daterangepicker .end-date {
        background-color: transparent !important;
        color: #0d47a1 !important;
        position: relative;
      }

      .daterangepicker td.in-range {
        background-color: transparent !important;
        position: relative;
        color: #013991 !important;
      }

      .daterangepicker td.in-range:not(.start-date)::after,
      .daterangepicker td.in-range:not(.end-date)::before {
        content: "";
        position: absolute;
        width: 37px;
        height: 22px;
        left: 0;
        top: 6.5px;
        background: rgba(194, 217, 237, 0.3);
      }

      .daterangepicker .start-date::before,
      .daterangepicker .end-date::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 22px;
        right: -2px;
        top: 6.5px;
        background: transparent;
        background-color: rgba(194, 217, 237, 0.5);
      }

      .daterangepicker .end-date::before {
        left: -2px;
      }

      .daterangepicker .start-date::after,
      .daterangepicker .end-date::after {
        content: "";
        position: absolute;
        width: 22px !important;
        height: 22px;
        left: 7px !important;
        border-radius: 20px;
        border: 1px solid #0d47a1;
        top: 6.5px;
        background: transparent !important;
      }

      .daterangepicker .calendar-table td {
        min-width: 37px !important;
      }

      .daterangepicker th.month {
        font-family: "SofiaPro";
        font-weight: 800;
        color: #93d500;
        font-size: 18px !important;
        height: 60px !important;
      }

      .off.ends {
        visibility: hidden;
        opacity: 0;
      }

      .start-date.end-date::before {
        content: none;
      }

      .daterangepicker .drp-buttons .drp-selected {
        float: left;
        padding: 15px;
        font-size: 15px;
        font-family: "SofiaProLight";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        color: #255097;
      }

      .daterangepicker .drp-buttons .drp-selected::before {
        content: "Input date range";
        display: block;
        text-align: left;
        font-family: "SofiaProLight";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        color: #255097;
        margin-bottom: 5px;
      }

      .daterangepicker .drp-buttons .btn {
        margin-left: 8px !important;
        border: 2px solid #98d801;
        border-radius: 3px;
        background: white;
        padding: 11px 20px !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 18px !important;
        color: #0d529d;
        font-family: SofiaProSemiBold;
        margin-top: 20px;
        margin-right: 15px !important;
      }

      .daterangepicker .drp-buttons .btn[disabled] {
        cursor: not-allowed;
      }

      .daterangepicker .drp-buttons .btn:hover {
        background: #98d801;
        color: white;
      }

      .daterangepicker .drp-buttons {
        padding: 0px 8px 20px 8px !important;
      }

      .daterangepicker td.available:hover,
      .daterangepicker th.available:hover {
        background-color: transparent !important;
      }

      .jvectormap-container .jvectormap-zoomin {
        line-height: 7px;
      }

      .jvectormap-container .jvectormap-zoomout {
        line-height: 6px;
      }

      .jvectormap-tip {
        z-index: 1;
      }

      .react-tel-input .form-control {
        width: calc(100% - 50px) !important;
        border: 1px solid #8aa3d1 !important;
        border-radius: 4px !important;
        padding-left: 10px !important;
        margin-left: 50px !important;
        color: #0d47a1 !important;
      }

      .react-tel-input .form-control::placeholder {
        color: #8aa3d1 !important;
        font-family: SofiaProLight, SofiaProMedium, SofiaProSemiBold, SofiaPro,
          Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
        opacity: 1;
      }

      .react-tel-input .form-control:-ms-input-placeholder {
        color: #8aa3d1 !important;
        font-family: SofiaProLight, SofiaProMedium, SofiaProSemiBold, SofiaPro,
          Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
      }

      .react-tel-input .form-control::-ms-input-placeholder {
        color: #8aa3d1 !important;
        font-family: SofiaProLight, SofiaProMedium, SofiaProSemiBold, SofiaPro,
          Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
      }

      .react-tel-input .flag-dropdown {
        border: 1px solid #8aa3d1 !important;
        border-radius: 4px !important;
        background-color: #fff !important;
      }

      .react-tel-input .selected-flag:hover,
      .react-tel-input .selected-flag:focus {
        background-color: transparent !important;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background: lightgrey;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #93d500;
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }

      .tab-content > .tab-pane:not(.active) {
        display: block;
        height: 0;
        overflow-y: hidden;
      }

      .my-react-country-flag {
        padding-bottom: 0 !important;
        margin-right: 10px;
        color: #0d47a1;
        border-color: #0d47a1;
      }

      .my-react-country-flag button {
        padding: 0 10px;
        color: #0d47a1;
        border: 1px solid rgba(13, 71, 161, 0.5);
        height: 100%;
      }

      .my-react-country-flag button:focus {
        padding: 0 10px;
        color: #0d47a1;
        border: 2px solid #0d47a1;
      }

      .my-react-country-flag button svg,
      .my-react-country-flag ul svg {
        width: 35px;
      }

      .my-react-country-flag ul {
        padding: 0 10px;
      }

      .MuiAutocomplete-popper {
        border: 2px solid #0d47a1;
      }

      .no-scroll {
        overflow: hidden;
      }

      body .jvectormap-tip:not(:last-child) {
        display: none !important;
      }
    `}
  />
);

export default GlobalStyle;
