import { Button, Grid, Typography, FormLabel } from "@mui/material";
import MySelect from "components/ui/inputs/MySelect";
import React from "react";
import { apiSignUpStep1GetSubsidariyList } from "apis/auth";
import useBackdropProgress from "hooks/useBackdropProgress";
import { htmlDecode, setMenuListFirstItem } from "utils/common";
import { getSession } from "utils/jwt";

export default function Form6SignUpStep1({ onSubmit, onNext, defaultValues }) {
  const groupDetails = getSession()?.group;
  const [list, setList] = React.useState([]);
  const [locations, setLocations] = React.useState(
    (defaultValues?.locations?.length && defaultValues?.locations) || []
  );

  const [errorLocations, setErrorLocations] = React.useState(
    locations.map((item) => undefined)
  );

  const { toggleBackdropProgress } = useBackdropProgress();

  const handleSubmit = (e) => {
    let flag = false;
    let err = [...errorLocations];

    for (let i = 0; i < locations.length; i++) {
      let value =
        !locations?.[i]?.hasOwnProperty("location_subsidiary") ||
        locations?.[i]?.location_subsidiary === null
          ? groupDetails?.uuid
          : list?.find(
              (item) =>
                item?.id == locations?.[i]?.location_subsidiary ||
                item?.uuid == locations?.[i]?.location_subsidiary
            )?.value == undefined
          ? "undefined"
          : list?.find(
              (item) =>
                item?.id == locations?.[i]?.location_subsidiary ||
                item?.uuid == locations?.[i]?.location_subsidiary
            )?.value;

      if (!value || value == "undefined") {
        err[i] = "This field is required";
        flag = true;
      }
    }

    if (flag) {
      setErrorLocations(err);
      return;
    }
    onNext();
  };

  const setSubsidiaryList = async () => {
    toggleBackdropProgress(true);
    let groupData = {
      id: groupDetails.id,
      uuid: groupDetails.uuid,
      label: htmlDecode(groupDetails?.name),
      value: groupDetails?.uuid,
    };
    try {
      const subsidiaryListResponse = await apiSignUpStep1GetSubsidariyList({
        currentUserConfig: defaultValues,
      });

      const subsidiaryList = subsidiaryListResponse.map(function (item) {
        return {
          ...item,
          label: htmlDecode(item?.name),
          value: item?.uuid,
        };
      });
      setMenuListFirstItem(
        subsidiaryList ? [...subsidiaryList, groupData] : [groupData],
        setList,
        {
          hide: true,
        }
      );
    } catch (e) {
      setMenuListFirstItem([groupData], setList, {
        hide: true,
      });
    }
    toggleBackdropProgress(false);
  };

  React.useEffect(() => {
    setSubsidiaryList();
  }, []);

  const handleChange = (e, index) => {
    let callback = () => {
      let d = [...locations];
      d[index].location_subsidiary = e.target.value;
      setLocations(d);

      let err = [...errorLocations];
      err[index] = undefined;
      setErrorLocations(err);
    };
    onSubmit({
      locationId: locations[index].uuid,
      uuid: e.target.value != groupDetails?.uuid ? e.target.value : null,
      callback,
    });
  };

  const rows = () => {
    return (
      <>
        {list?.length &&
          locations.map((item, index) => {
            return (
              <>
                <Grid item xs={5} display="flex" alignItems="center">
                  <FormLabel>{item?.name}</FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <MySelect
                    menuList={list}
                    value={
                      !locations?.[index]?.hasOwnProperty(
                        "location_subsidiary"
                      ) || locations?.[index]?.location_subsidiary === null
                        ? groupDetails?.uuid
                        : list?.find(
                            (item) =>
                              item?.id ==
                                locations?.[index]?.location_subsidiary ||
                              item?.uuid ==
                                locations?.[index]?.location_subsidiary
                          )?.value === "undefined"
                        ? "undefined"
                        : list?.find(
                            (item) =>
                              item?.id ==
                                locations?.[index]?.location_subsidiary ||
                              item?.uuid ==
                                locations?.[index]?.location_subsidiary
                          )?.value
                    }
                    onChange={(e) => handleChange(e, index)}
                    errorMessage={errorLocations[index]}
                  />
                </Grid>
              </>
            );
          })}
      </>
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Please link all locations to the company they're under:
          </Typography>
        </Grid>

        <Grid item xs={5}></Grid>

        <Grid item xs={7}>
          <FormLabel>Subsidiaries/Parent Company:</FormLabel>
        </Grid>

        {rows()}

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
