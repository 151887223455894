import {
  Button,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  Typography,
  Divider,
} from "@mui/material";
import MyTextField from "../../../../ui/inputs/MyTextField";
import React from "react";
import MyCheckbox from "components/ui/inputs/MyCheckbox";

export default function FormWelcome({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit(e);
  };
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Welcome NAME!
          </Typography>{" "}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <MyCheckbox
            list={[
              { value: 1, label: "{name of buisness}" },
              { value: 2, label: "{name of buisness}" },
              { value: 2, label: "{name of buisness}" },
            ]}
            label="Please select the account that you would like to login to"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleSubmit}>
            Log in
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
