import {
  apiGetToken,
  apiLoginSubmit,
  apiResetPasswordStep1,
  apiResetPasswordStep2,
  apiResetPasswordStep3,
  apiSendSMSVerfication,
  apiSignUpStep1Step1,
  apiSignUpStep1Step2,
  apiSignUpStep1Step3,
  apiSignUpStep1Step4,
  getTokenFromRefreshToken,
} from "apis/auth";
import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../apis/ajax";
import {
  clearSession,
  getSession,
  isValidToken,
  setTokenData,
  setSession,
} from "../utils/jwt";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isMounted: false,
  isAuthenticated: false,
  isInitialized: false,
  isProfileComplete: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isMounted: true,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isProfileComplete: action.payload.isProfileComplete,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isMounted: true,
        isAuthenticated: true,
        isProfileComplete: action.payload.isProfileComplete,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isMounted: true,
        isAuthenticated: false,
        isProfileComplete: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isMounted: true,
        isAuthenticated: true,
        isProfileComplete: action.payload.isProfileComplete,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const navigate = useNavigate();

  const isProfileComplete = (currentUser) => {
    return parseInt(currentUser?.step) >= 6;
  };

  useEffect(() => {
    (async () => {
      const currentUser = getSession();

      try {
        if (!isValidToken()) {
          const tokenData = await getTokenFromRefreshToken();
          setTokenData(tokenData);
        }
      } catch (e) {
        console.log(e);
        const tokenData = await apiGetToken();
        setTokenData(tokenData);
      }

      if (Object.keys(currentUser)?.length) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isMounted: true,
            isAuthenticated: true,
            isProfileComplete: isProfileComplete(currentUser),
            user: currentUser,
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isMounted: true,
            isAuthenticated: false,
            isProfileComplete: isProfileComplete(currentUser),
            user: null,
          },
        });
        clearSession();
      }
    })();
  }, []);

  const logInSubmit = async (email, password) => {
    const data = await apiLoginSubmit({ email, password });
    const { current_user } = data;

    setSession({
      currentUser: current_user,
    });

    dispatch({
      type: SIGN_IN,
      payload: {
        isMounted: true,
        isAuthenticated: true,
        isProfileComplete: isProfileComplete(current_user),
        user: current_user,
      },
    });

    return data;
  };

  const logInSubmitOtp = async (otp) => {};

  const logInSubmitWelcome = async () => {};

  const signOut = async () => {
    setSession(null, null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        isMounted: true,
        isAuthenticated: true,
        isProfileComplete: false,
        user: user,
      },
    });
  };

  const resetPasswordStep1 = async ({ email }) => {
    return await apiResetPasswordStep1({ email });
  };

  const resetPasswordStep2 = async ({ timestamp, account_id, token }) => {
    return await apiResetPasswordStep2({ timestamp, account_id, token });
  };

  const sendSMSVerfication = async ({ id, otp }) => {
    return await apiSendSMSVerfication({ id, otp });
  };

  const resetPasswordStep3 = async ({ password, id }) => {
    return await apiResetPasswordStep3({ password, id });
  };

  const resetPasswordSubmitPassword = ({ password }) => {};

  const signUpStep1Step1 = async (data) => {
    return await apiSignUpStep1Step1(data);
  };

  const signUpStep1Step2 = async (data) => {
    return await apiSignUpStep1Step2(data);
  };

  const signUpStep1Step3 = async (data) => {
    return await apiSignUpStep1Step3(data);
  };

  const signUpStep1Step4 = async (data) => {
    return await apiSignUpStep1Step4(data);
  };

  const logOut = () => {
    dispatch({
      type: INITIALIZE,
      payload: {
        isMounted: true,
        isAuthenticated: false,
        isProfileComplete: false,
        user: null,
      },
    });
    clearSession();
    navigate("/auth/login");
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        logInSubmit,
        logInSubmitOtp,
        logInSubmitWelcome,
        signOut,
        signUp,

        resetPasswordStep1,
        resetPasswordStep2,
        sendSMSVerfication,
        resetPasswordStep3,
        resetPasswordSubmitPassword,

        signUpStep1Step1,
        signUpStep1Step2,
        signUpStep1Step3,
        signUpStep1Step4,

        logOut,

        currentUser: state.user,
        isAuthenticated: state.isAuthenticated,
        isInitialized: state.isInitialized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
