import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function MyDialog({ children, ...props }) {
  return (
    <Dialog {...props}>
      <DialogActions
        sx={{
          padding: 0,
        }}
      >
        <DialogTitle sx={{ flexGrow: "1" }}>{props?.dialogTitle}</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      {children}
    </Dialog>
  );
}
