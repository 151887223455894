import { groupBy, round } from "lodash";
import Badge1 from "../assets/svg/badge1.svg";
import Badge2 from "../assets/svg/badge2.svg";
import Badge3 from "../assets/svg/badge3.svg";
import Badge4 from "../assets/svg/badge4.svg";
import IconBin from "assets/svg/analytics/ActivityAnalysis/Bin.svg";
import IconComputer from "assets/svg/analytics/ActivityAnalysis/Computer.svg";
import IconElectricity from "assets/svg/analytics/ActivityAnalysis/Electricity.svg";
import IconThunder from "assets/svg/analytics/ActivityAnalysis/Thunder.svg";

import IconAccomodation from "assets/svg/analytics/ActivityAnalysis/Accomodation.svg";
import IconFuel from "assets/svg/analytics/ActivityAnalysis/Fuel.svg";
import IconMeter from "assets/svg/analytics/ActivityAnalysis/Meter.svg";
import IconPlane from "assets/svg/analytics/ActivityAnalysis/Plane.svg";

import IconAir from "assets/svg/analytics/ActivityAnalysis/Air.svg";
import IconRail from "assets/svg/analytics/ActivityAnalysis/Rail.svg";
import IconRoad from "assets/svg/analytics/ActivityAnalysis/Road.svg";
import IconSea from "assets/svg/analytics/ActivityAnalysis/Sea.svg";
import DownArrow from "assets/svg/down_arrow.svg";
import UpArrow from "assets/svg/up_arrow.svg";
import DefaultImage from "assets/svg/defaultImage.svg";

export const formatNumber = (number, rounding, isFormatted = false, config) => {
  let localeArguments;
  if (config?.currency) {
    localeArguments = {
      style: "currency",
      currency: config?.currency,
      minimumFractionDigits: rounding,
      maximumFractionDigits: rounding,
    };
  } else {
    localeArguments = {
      minimumFractionDigits: rounding,
      maximumFractionDigits: rounding,
    };
  }

  let floatingNumber = parseFloat(number);
  if (isNaN(floatingNumber)) {
    return isFormatted
      ? Number(0).toLocaleString(undefined, localeArguments)
      : Number(0);
  }
  if (floatingNumber == 0) {
    return isFormatted ? Number(0).toFixed(rounding) : Number(0);
  }

  if (config?.noRounding) {
    let num = number.toString();
    return num.indexOf(".") == -1
      ? num
      : num.toString().slice(0, num.indexOf(".") + rounding + 1);
  }

  let formattedString;
  let roundingNumber;
  if (rounding) {
    roundingNumber = round(floatingNumber, rounding);

    try {
      formattedString = isFormatted
        ? roundingNumber.toLocaleString("en-US", localeArguments)
        : roundingNumber;
    } catch (e) {
      formattedString = isFormatted
        ? roundingNumber.toLocaleString("en-US", {
            maximumFractionDigits: rounding,
            minimumFractionDigits: rounding,
          })
        : roundingNumber;
    }
  } else {
    try {
      formattedString = isFormatted
        ? floatingNumber.toLocaleString(undefined, localeArguments)
        : floatingNumber;
    } catch (e) {
      formattedString = isFormatted
        ? floatingNumber.toLocaleString(undefined, {
            maximumFractionDigits: rounding,
            minimumFractionDigits: rounding,
          })
        : floatingNumber;
    }
  }

  if (config?.includeSign && floatingNumber > 0) {
    return "+" + formattedString;
  }

  return formattedString;
};

export const getScopeColor = ({ theme, scope }) => {
  switch (scope) {
    case "1":
    case 1:
      return theme.palette.blue;
    case "2":
    case 2:
      return theme.palette.gold;
    case "3":
    case 3:
      return theme.palette.green.light;
    case "4":
    case 4:
      return theme.palette.primary;
    default:
      return theme.palette.blue;
  }
};

export const getScopeBadge = ({ scope }) => {
  switch (scope) {
    case "1":
    case 1:
      return Badge1;
    case "2":
    case 2:
      return Badge2;
    case "3":
    case 3:
      return Badge3;
    case "4":
    case 4:
      return Badge4;
    default:
      return Badge1;
  }
};

export const checkIfValueExists = (value) => {
  const hasValue = ![undefined, null, NaN, 0, "", false, "0"].includes(value);
  return hasValue ? value : false;
};

export const getCurrencySymbolUsingCountryCode = (currency) => {
  return Number(0)
    .toLocaleString("en", {
      style: "currency",
      currency: currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .replace("0.00", "");
};

export const getScopesData = ({ theme, data }) => {
  const themeData = [
    {
      badge: getScopeBadge({ scope: 1 }),
      label: "Scope 1",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 1 }),
      key: 1,
      isGreatest: false,
    },
    {
      badge: getScopeBadge({ scope: 2 }),
      label: "Scope 2",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 2 }),
      key: 2,
      isGreatest: false,
    },
    {
      badge: getScopeBadge({ scope: 3 }),
      label: "Scope 3",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 3 }),
      key: 3,
      isGreatest: false,
    },
    {
      badge: getScopeBadge({ scope: 4 }),
      label: "Total",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 4 }),
      key: 4,
      isGreatest: false,
    },
  ];

  const filteredData = data?.filter((item) => item.tr_scope) || [];

  let filteredDataObj = Object.assign(
    {},
    ...filteredData.map((data) => {
      return { [data.tr_scope]: data };
    })
  );

  let totalScope = 0;
  let greatest_value = -1;

  const scopeDataArray = themeData.map((item) => {
    let trFootPrint = filteredDataObj[item.key]?.tr_footprint || 0;
    totalScope += +formatNumber(trFootPrint);

    if (trFootPrint > greatest_value) greatest_value = item?.key - 1;

    return {
      ...item,
      value: formatNumber(trFootPrint, 2, true),
      numeric_value: parseFloat(trFootPrint),
    };
  });
  scopeDataArray[3].value = formatNumber(totalScope, 2, true);
  scopeDataArray[3].numeric_value = totalScope;

  scopeDataArray[greatest_value].isGreatest = true;

  const scopeDatafinal = scopeDataArray.map((item) => {
    return {
      ...item,
      percentage_value: (item?.numeric_value / totalScope) * 100,
    };
  });

  return scopeDatafinal;
};

export const getPercentageValue = (value, total, decimalPoints = 2) => {
  return formatNumber((value * 100) / total, decimalPoints);
};

export const setMenuListFirstItem = (list, setFunc, options) => {
  let val = {
    label: htmlDecode(options?.label) || "Select",
    value: "undefined",
    isDisabled: true,
    hide: options?.hide || false,
  };
  if (typeof setFunc == "function") {
    setFunc([val, ...list]);
  } else {
    return [val, ...list];
  }
};

export const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const removeItemAll = (arr, value) => {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

export const getCumulativeSumOfArray = (arr) => {
  let cumulative = [];
  let total = 0;
  for (let i = 0; i < arr.length; i++) {
    total += arr[i];
    cumulative.push(total);
  }
  return cumulative;
};

export const getCarbonFootprintScopeData = ({ theme, data }) => {
  const footPrintScopeData = [0, 0, 0];
  const scopeData = getScopesData({ theme, data });

  scopeData.slice(0, -1).map((item, index) => {
    footPrintScopeData[index] = parseFloat(
      formatNumber(item?.percentage_value, 2, false)
    );
  });

  let isEmpty = footPrintScopeData?.every(
    (item) => item === 0 || item !== item
  );

  return {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    values: isEmpty ? [] : footPrintScopeData,
    colors: [theme.palette.blue, theme.palette.gold, theme.palette.green.light],
    scopeData: isEmpty ? [] : scopeData,
  };
};

export const getCarbonFootprintVsLatestYearData = ({
  theme,
  recordsData,
  scopeData,
}) => {
  const themeData = () => [
    {
      badge: getScopeBadge({ scope: 1 }),
      label: "Scope",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 1 }),
      key: 1,
      percent: 0,
      change: 0,
    },
    {
      badge: getScopeBadge({ scope: 2 }),
      label: "Scope",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 2 }),
      key: 2,
      percent: 0,
      change: 0,
    },
    {
      badge: getScopeBadge({ scope: 3 }),
      label: "Scope",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 3 }),
      key: 3,
      percent: 0,
      change: 0,
    },
    {
      badge: getScopeBadge({ scope: 4 }),
      label: "Total",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 4 }),
      key: 4,
      percent: 0,
      change: 0,
    },
  ];

  let scopeDataArray = getScopesData({ theme, data: scopeData });
  let recordComparisonData = {};

  recordsData &&
    Object.keys(recordsData).map((key) => {
      const yearThemeData = themeData();
      yearThemeData[0].change =
        recordsData?.[key]?.record_scope1 - scopeDataArray?.[0]?.numeric_value;
      yearThemeData[1].change =
        recordsData?.[key]?.record_scope2 - scopeDataArray?.[1]?.numeric_value;
      yearThemeData[2].change =
        recordsData?.[key]?.record_scope3 - scopeDataArray?.[2]?.numeric_value;
      yearThemeData[3].change =
        recordsData?.[key]?.record_total - scopeDataArray?.[3]?.numeric_value;

      yearThemeData[0].percent =
        recordsData?.[key]?.record_scope1 == 0
          ? 0
          : (scopeDataArray?.[0]?.numeric_value /
              recordsData?.[key]?.record_scope1) *
            100;
      yearThemeData[1].percent =
        recordsData?.[key]?.record_scope2 == 0
          ? 0
          : (scopeDataArray?.[1]?.numeric_value /
              recordsData?.[key]?.record_scope2) *
            100;
      yearThemeData[2].percent =
        recordsData?.[key]?.record_scope3 == 0
          ? 0
          : (scopeDataArray?.[2]?.numeric_value /
              recordsData?.[key]?.record_scope3) *
            100;
      yearThemeData[3].percent =
        recordsData?.[key]?.record_total == 0
          ? 0
          : (scopeDataArray?.[3]?.numeric_value /
              recordsData?.[key]?.record_total) *
            100;

      recordComparisonData[recordsData?.[key]?.record_year] = yearThemeData;
    });

  return Object.keys(recordComparisonData)?.length
    ? recordComparisonData
    : { undefined: themeData() };
};

export const getTotalCarbonIntensityCardData = ({ data }) => {
  return data?.total_carbon_intensity;
};

export const getTargetListData = ({ theme, data }) => {
  const themeData = () => [
    {
      badge: getScopeBadge({ scope: 1 }),
      label: "Target:",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 1 }),
      key: 1,
    },
    {
      badge: getScopeBadge({ scope: 2 }),
      label: "Target:",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 2 }),
      key: 2,
    },
    {
      badge: getScopeBadge({ scope: 3 }),
      label: "Target:",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 3 }),
      key: 3,
    },
    {
      badge: getScopeBadge({ scope: 4 }),
      label: "Target:",
      type: "tCO₂e",
      color: getScopeColor({ theme, scope: 4 }),
      key: 4,
    },
  ];

  let targetData = {};

  data?.map((item) => {
    let yearThemeData = themeData();
    yearThemeData[0].targetValue = item?.target_scope1;
    yearThemeData[1].targetValue = item?.target_scope2;
    yearThemeData[2].targetValue = item?.target_scope3;
    yearThemeData[3].targetValue = item?.target_total;

    targetData[item?.target_year] = yearThemeData;
  });

  return targetData;
};

export const getMonthlyScopeData = ({ data }) => {
  const monthlyData = {
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    4: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  data?.chart_data &&
    Object.keys(data?.chart_data)?.map((month) => {
      Object.keys(data?.chart_data?.[month])?.map((scope) => {
        monthlyData[scope][parseInt(month?.slice(-2)) - 1] = parseFloat(
          formatNumber(data?.chart_data?.[month][scope], 2, false)
        );
        monthlyData[4][parseInt(month?.slice(-2)) - 1] = parseFloat(
          formatNumber(
            parseFloat(monthlyData[4][parseInt(month?.slice(-2)) - 1]) +
              parseFloat(data?.chart_data?.[month][scope]),
            2,
            false
          )
        );
      });
    });

  let isEmpty = true;
  Object.keys(monthlyData)?.map((scope) => {
    if (isEmpty) {
      isEmpty = monthlyData?.[scope]?.every((item) => item === 0);
    }
  });

  return isEmpty ? [] : monthlyData;
};

export const getCumulativeMonthlyScopeData = ({ data }) => {
  let monthlyScopeData = getMonthlyScopeData({ data });
  Object.keys(monthlyScopeData)?.map((scope) => {
    monthlyScopeData[scope] = getCumulativeSumOfArray(monthlyScopeData[scope]);
  });
  return monthlyScopeData;
};

export const getYearsListForDropdown = (
  startYear,
  endYear,
  reverse = false
) => {
  const currentYear = new Date().getFullYear();
  const defaultValue = { value: "undefined", label: "Year", hide: true };
  if (!startYear) {
    startYear = currentYear;
  }
  if (!endYear) {
    endYear = currentYear;
  }
  if (startYear > endYear) {
    return [defaultValue];
  }
  const options = [];
  for (let i = endYear; i >= startYear; i--) {
    options.push({ value: i, label: i.toString() });
  }
  return reverse
    ? [defaultValue, ...options.reverse()]
    : [defaultValue, ...options];
};

export const numbersOnly = (event) => {
  const charCode = event.charCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

export const floatNumbersOnly = (event) => {
  const charCode = event.charCode;
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }
};

export const formatDataForSelectBox = (data, select = true, key = "uuid") => {
  let formattedData = data.map((item) => {
    return {
      ...item,
      value: key == "uuid" ? item.uuid : item?.[key],
      label: item.name ? htmlDecode(item.name) : htmlDecode(item?.label),
    };
  });
  if (select) {
    return [
      { value: "undefined", label: "Select", isDisabled: true, hide: true },
      ...formattedData,
    ];
  }
  return [...formattedData];
};

export const convertArrayToSelectFormat = (data) => {
  let formattedData = data.map((item) => {
    return {
      value: item,
      label: htmlDecode(item),
    };
  });
  return [...formattedData];
};

export const convertFileToBinary = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const binaryData = new Uint8Array(arrayBuffer);
      resolve(binaryData);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getStaticDetailsActivityAnalysis = (name) => {
  let key = name?.toLowerCase().replaceAll(" ", "_");
  switch (key) {
    case "electricity":
      return { icon: IconElectricity, unit: "kWh", label: "Electricity" };
    case "general_waste":
      return { icon: IconBin, unit: "lbs", label: "General Waste" };
    case "electric_waste":
      return { icon: IconThunder, unit: "lbs", label: "Electric Waste" };
    case "cpu_computers":
      return { icon: IconComputer, unit: "GB", label: "CPU Computers" };
    case "air_miles":
      return { icon: IconPlane, unit: "mi", label: "Air" };
    case "fuel_consumption":
      return { icon: IconFuel, unit: "gal", label: "Fuel Consumption" };
    case "accomodation":
      return { icon: IconAccomodation, unit: "nights", label: "Accomodation" };
    case "other_miles_traveled":
      return { icon: IconMeter, unit: "mi", label: "Other Distance Traveled" };
    case "air":
      return { icon: IconAir, unit: "mi", label: "Air" };
    case "rail":
      return { icon: IconRail, unit: "mi", label: "Rail" };
    case "road":
      return { icon: IconRoad, unit: "mi", label: "Road" };
    case "sea":
      return { icon: IconSea, unit: "mi", label: "Sea" };
    default:
      return { icon: IconBin, unit: "mi", label: "Electricity" };
  }
};

export const getMapYourFootprintData = ({ data }) => {
  let result = {
    markers: [],
    carbon_intensity: [],
    carbon_footprint: [],
  };

  let sumAllCarbonIntensity = 0;
  data?.rows.map((item) => {
    item.carbon_intensity =
      parseFloat(item?.total_spend) == 0
        ? 0
        : (item?.total_footrpint / item?.total_spend) * 1000;

    sumAllCarbonIntensity += item?.carbon_intensity;
  });

  data.total.total_intensity = sumAllCarbonIntensity;

  data?.rows.map((item) => {
    let label = item?.name;
    let latitude = item?.latitude;
    let longitude = item?.longitude;

    result.markers.push({
      latLng: [latitude, longitude],
      name: label,
    });

    result.carbon_intensity.push({
      location: label,
      per_dollar_revenue:
        parseFloat(item?.total_spend) == 0
          ? 0
          : (item?.total_footrpint / item?.total_spend) * 1000,
      carbonFootprintPercentage:
        parseFloat(data?.total?.total_intensity) == 0
          ? 0
          : getPercentageValue(
              item?.carbon_intensity,
              data?.total?.total_intensity
            ),
    });

    result.carbon_footprint.push({
      country: label,
      carbonFootprint: item?.total_footrpint,
      carbonFootprintPercentage:
        parseFloat(data?.total?.totals_footprint) == 0
          ? 0
          : getPercentageValue(
              item?.total_footrpint,
              data?.total?.totals_footprint
            ),
    });
  });

  return result;
};

export const generateColorCodes = (scope = 1, shades) => {
  let colors = {
    1: ["#9ACAF3", "#C2D9ED", "#D1E3F2", "#E1ECF5", "#E6F0FA", "#EDF6FF"],
    2: ["#FEBA00", "#F6C746", "#F9D87E", "#FFE7A7", "#FDF1D2", "#FFFAED"],
    3: ["#A5D082", "#C5DEB1", "#D4E8C5", "#DEEED1", "#E8F2E0", "#ECF4E6"],
    4: ["#03307A", "#255097", "#5375AD", "#95B0CE", "#BECEDF", "#E3ECF5"],
  };

  const originalLength = colors[scope]?.length;
  let array = colors[scope];
  for (let i = originalLength; i <= shades; i++) {
    array[i] = array[i % originalLength];
  }
  return array;

  // for (let i = shades + 10; i > 0; i--) {
  //   var _opacity = Math.round(Math.min(Math.max(i / shades || 1, 0), 1) * 255);
  //   colors.push(color + _opacity.toString(16).toUpperCase());
  // }

  // return colors.slice(0, -10);
};

export const getSupplierDetailsByScopeData = ({ data }) => {
  return data?.rows
    .sort(function (x, y) {
      if (parseFloat(x?.total_footrpint) < parseFloat(y?.total_footrpint))
        return 1;
      if (parseFloat(x?.total_footrpint) > parseFloat(y?.total_footrpint))
        return -1;
      return 0;
    })
    .filter((item) => parseFloat(item?.total_footrpint))
    .map((item) => {
      return {
        supplier:
          item?.address__country_code ||
          item?.tr_industry ||
          item?.name ||
          item?.title ||
          item?.category,
        carbonFootprintValue: item?.total_footrpint,
        carbonFootprint: item?.total_footrpint + " tCO₂e",
        carbonFootprintPercentage:
          parseFloat(data?.total?.totals_footprint) == 0
            ? 0
            : getPercentageValue(
                item?.total_footrpint,
                data?.total?.totals_footprint
              ),
        countryCode: item?.address__country_code_1,
        industryId: item?.tr_industry_1,
        projectId: item?.id,
        categoryId: item?.category_1,
        id:
          item?.address__country_code_1 ||
          item?.tr_industry_1 ||
          item?.id ||
          item?.category_1,
      };
    });
};

export const htmlDecode = (input) => {
  if (input === "") {
    return "";
  }
  if (!input) {
    return null;
  }
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const getSupplierBreakDownDetailsByScopeData = ({
  data,
  supplierDetails,
}) => {
  return data
    ?.sort(function (x, y) {
      if (parseFloat(x?.total_footrpint) < parseFloat(y?.total_footrpint))
        return 1;
      if (parseFloat(x?.total_footrpint) > parseFloat(y?.total_footrpint))
        return -1;
      return 0;
    })
    .map((item) => {
      return {
        supplier: item?.name,
        carbonFootprint:
          formatNumber(item?.total_footrpint, 2, true) + " tCO₂e",
        carbonFootprintPercentage:
          parseFloat(supplierDetails?.carbonFootprintValue) == 0
            ? 0
            : getPercentageValue(
                item?.total_footrpint,
                supplierDetails?.carbonFootprintValue
              ),
        industry: item?.tr_industry || item?.tr_industry_2,
      };
    });
};

export const getActivityAnalysisData = ({ data, type }) => {
  let staticDetails = getStaticDetailsActivityAnalysis(type);

  let generalInfo =
    data?.[type.toLowerCase().replaceAll(" ", "_")]?.general_info?.[0];

  let subsidiariesInfo =
    data?.[type.toLowerCase().replaceAll(" ", "_")]?.subsidiaries;

  let locationsInfo =
    data?.[type.toLowerCase().replaceAll(" ", "_")]?.locations;

  const locationArray =
    typeof locationsInfo === "object"
      ? Object.keys(locationsInfo)
          .map((key) => {
            const item = locationsInfo[key];
            if (key !== "dimension") {
              return {
                task_footprint: formatNumber(item.task_footprint, 2, true),
                task_source: item.task_source,
                task_location_name: item.task_location_name,
                total_value: `${item.total_value} ${locationsInfo.dimension}`,
              };
            }
            return null;
          })
          .filter(Boolean)
      : [];

  const subsidiaryArray =
    typeof subsidiariesInfo === "object"
      ? Object.keys(subsidiariesInfo)
          .map((key) => {
            const item = subsidiariesInfo[key];
            if (key !== "dimension") {
              return {
                task_footprint: formatNumber(item.task_footprint, 2, true),
                task_source: item.task_source,
                task_subsidiary_name: item.task_subsidiary_name,
                total_value: `${item.total_value} ${subsidiariesInfo.dimension}`,
              };
            }
            return null;
          })
          .filter(Boolean)
      : [];

  return {
    icon: staticDetails?.icon,
    title: type,
    label: staticDetails?.label,
    value:
      (generalInfo?.task_energy_value_kwh ||
        generalInfo?.field_data_being_stored_gb ||
        generalInfo?.field_total_distance ||
        generalInfo?.field_volume_in_gal ||
        generalInfo?.field_number_of_nights ||
        generalInfo?.field_weight_total_lbs ||
        "0") +
      " " +
      staticDetails?.unit,
    carbonFootprint:
      formatNumber(generalInfo?.task_footprint, 2, true) + " tCO₂e",
    source: generalInfo?.source,
    scope: getScopeBadge({ scope: generalInfo?.scope }),
    subsidiaries: subsidiaryArray,
    locations: locationArray,
  };
};

export const getArrow = (type) => {
  switch (type) {
    case "up":
      return UpArrow;
    case "down":
      return DownArrow;
    default:
      return UpArrow;
  }
};

export const getBoardItemSpotlightData = ({ data }) => {
  return data
    ?.filter((item) => item?.spotlight)
    ?.map((item) => {
      return {
        logo: DefaultImage,
        name: item?.name,
        cost: "$1,2000,000",
        carbonFootprint: item?.footprint,
        carbonIntensity: item?.carbon_intensity,
        carbonIntensityPercentage: 50,
        uuid: item?.uuid,
      };
    });
};

export const getLocationsMapData = ({ data }) => {
  let result = [];

  data?.map((item) => {
    let label = item?.name;
    let latitude = item?.latitude;
    let longitude = item?.longitude;

    result.push({
      latLng: [latitude, longitude],
      name: label,
    });
  });

  return result;
};

export const getTargetsData = ({ data }) => {
  return groupBy(data, "target_duration");
};

export const getClimatiqApiLabel = (value) => {
  switch (value) {
    case "1":
    case 1:
      return "Exiobase";
    default:
      return "Moody's";
  }
};

export const areAllValuesNull = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      return false;
    }
  }
  return true;
};

export const dangerousHtml = (value, nullValue) => {
  if (!value && nullValue) {
    return nullValue;
  }
  if (!value) {
    return "NA";
  }
  return value;
};
