import axios from "axios";
import { getAccessToken, setTokenData } from "utils/jwt";
import { getTokenFromRefreshToken } from "./auth";
// import { setupCache } from "axios-cache-adapter";

// const cache = setupCache({
//   maxAge: 15 * 60 * 100,
// });

let baseURL = "";
switch (window.location.origin) {
  case "https://axis-ai.carbonpay.io":
    baseURL = "https://live-axis.pantheonsite.io";
    break;
  case "https://carbonpayreactdashboard.web.app":
    baseURL = "https://dev-axis.pantheonsite.io";
    break;
  default:
    baseURL = "https://dev-axis.pantheonsite.io";
    break;
}

const instance = axios.create({
  baseURL: baseURL,
  // adapter: cache.adapter,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (
      token &&
      !["/user/login?_format=json", "/oauth/token"].includes(config?.url)
    ) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      !["/oauth/token"].includes(originalRequest.url) &&
      [401].includes(error?.response?.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return getTokenFromRefreshToken().then((res) => {
        setTokenData(res);
        originalRequest.headers.Authorization = "Bearer " + getAccessToken();
        return axios(originalRequest);
      });
    }
    return Promise.reject(error);
  }
);

const responseHandler = (res) => {
  return res.data;
};

const errorHandler = (err) => {
  console.log(err);
  throw err?.response?.data || "Something went wrong!";
};

const ajax = async (option) => {
  return instance(option).then(responseHandler).catch(errorHandler);
};

export default ajax;
