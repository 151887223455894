import ajax from "./ajax";
import { getAccessToken, getSession } from "utils/jwt";

export const apiUploadDataFilesSupplier = ({ file }) => {
  const current_user = getSession();

  return ajax({
    method: "post",
    url: "/csv-load/import/supplier",
    params: {
      _format: "json",
      user_id: current_user?.uid,
      group_id: current_user?.group?.id,
      country: "US",
    },
    headers: {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    data: file,
  });
};

export const apiUploadDataFilesTransaction = ({ file, dateType }) => {
  const current_user = getSession();

  return ajax({
    method: "post",
    url: "/csv-load/import/transaction",
    params: {
      _format: "json",
      user_id: current_user?.uid,
      group_id: current_user?.group?.id,
      country: dateType || "US",
    },
    headers: {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    data: file,
  });
};

export const apiGetProjectList = () => {
  const current_user = getSession();

  return ajax({
    method: "get",
    url: "/rest/project-list/" + current_user?.group?.id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetDepartmentList = () => {
  const current_user = getSession();

  return ajax({
    method: "get",
    url: "/rest/department-list/" + current_user?.group?.id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetLocationList = () => {
  const current_user = getSession();

  return ajax({
    method: "get",
    url: "/rest/location-list/" + current_user?.group?.id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetImportStatus = (url) => {
  if (url == "/rest/import-status?feed_id=undefined") {
    return false;
  }
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
