import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const Title = styled(MuiTypography)`
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 112%;
  text-align: center;
  margin-bottom: 1rem;
`;

export default function Form2SignUpStep3({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Title>Success!</Title>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <MuiTypography fontWeight="bold" textAlign="center" maxWidth={400}>
            We have received your supplier file. Be on the lookout for a welcome
            email that will give you access to your Axis.
          </MuiTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <MuiTypography fontWeight="bold" textAlign="center" maxWidth={400}>
            In the meantime, feel free to reach back out to the Customer Success
            Representative you’ve been in contact with if you have any further
            questions.
          </MuiTypography>
        </Grid>
      </Grid>
    </div>
  );
}
