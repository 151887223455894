import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Divider,
  Box,
  Grid,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import { useTheme } from "@emotion/react";
import colors from "theme/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function SustainableStartup({
  required = true,
  label,
  labelSx = {},
  placeholder = "",
  multiselect,
  onePerCategory,
  selectSx = {},
  selectSxPaperProps = {},
  onChangeData = () => {},
  errorMessage = "",
  defaultValue = [],
  restrict = [],
}) {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = useState(
    _.map(defaultValue, "uuid")
  );

  const checkboxOptions = React.useMemo(() => {
    const data = {
      group: [
        {
          value: currentUser?.group?.uuid,
          label: currentUser?.group?.name,
          uuid: currentUser?.uuid,
          id: currentUser?.group?.id,
          type: "group",
          field: "sstDropdown",
          // disabled: true,
          // checked: true,
        },
      ],
      subsidiaries: [],
      locations: [],
    };

    currentUser["subsidiaries"] &&
      currentUser["subsidiaries"].map((item, index) => {
        if (item?.name) {
          data.subsidiaries.push({
            label: item?.name,
            value: item?.uuid,
            uuid: item?.uuid,
            id: item?.id,
            type: "subsidiaries",
            field: "sstDropdown",
          });
        }
      });

    currentUser["locations"] &&
      currentUser["locations"].map((item, index) => {
        if (item?.name) {
          data.locations.push({
            label: item?.name,
            value: item?.uuid,
            uuid: item?.uuid,
            id: item?.id,
            type: "locations",
            field: "sstDropdown",
          });
        }
      });

    restrict.map((item) => {
      delete data[item];
    });

    return [
      {
        groupName: "Group",
        options: data.group || [],
        showDivider: true,
      },
      {
        groupName: "Subsidiaries",
        options: data.subsidiaries || [],
        showDivider: true,
      },
      {
        groupName: "Locations",
        options: data.locations || [],
        showDivider: false,
      },
    ];
  }, []);

  const handleOptionToggle = (option) => () => {
    if (onePerCategory) {
      const categoryIndex = checkboxOptions.findIndex((group) =>
        group.options.some(
          (categoryOption) => categoryOption.uuid === option.uuid
        )
      );

      if (categoryIndex !== -1) {
        const selectedCategoryOption = selectedOptions.find((selectedOption) =>
          checkboxOptions[categoryIndex].options.some(
            (categoryOption) => categoryOption.uuid === selectedOption
          )
        );

        if (selectedCategoryOption === option.uuid) {
          setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.filter(
              (selectedOption) => selectedOption !== option.uuid
            )
          );
        } else {
          setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.filter((selectedOption) =>
              checkboxOptions[categoryIndex].options.every(
                (categoryOption) => categoryOption.uuid !== selectedOption
              )
            )
          );
          setSelectedOptions((prevSelectedOptions) => [
            ...prevSelectedOptions,
            option.uuid,
          ]);
        }
      }
    } else if (multiselect) {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.includes(option.uuid)
          ? prevSelectedOptions.filter(
              (selectedOption) => selectedOption !== option.uuid
            )
          : [...prevSelectedOptions, option.uuid]
      );
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.includes(option.uuid) ? [] : [option.uuid]
      );
    }
  };

  const isOptionSelected = (option) => selectedOptions.includes(option.uuid);

  const getObjectsByUUID = (uuidArray, objectArray) => {
    return _.flatMap(objectArray, "options").filter((option) =>
      uuidArray.includes(option.uuid)
    );
  };

  useEffect(() => {
    onChangeData({
      field: "sstDropdown",
      value: getObjectsByUUID(selectedOptions, checkboxOptions),
    });
  }, [selectedOptions]);

  return (
    <FormControl size="small" error={Boolean(errorMessage)}>
      {label && (
        <FormLabel sx={{ ...labelSx }}>
          {label} {required ? "*" : undefined}
        </FormLabel>
      )}
      <Box mt={label ? theme.spacing(2) : 0} width="100%">
        <Select
          sx={{
            width: "100%",
            ...selectSx,
          }}
          MenuProps={{
            PaperProps: {
              style: {
                border: `2px solid ${colors.primary.main}`,
                overflowY: "auto",
                maxHeight: "25rem",
                ...selectSxPaperProps,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getcontentanchorel: null,
          }}
          IconComponent={KeyboardArrowDownIcon}
          multiple={multiselect || onePerCategory}
          value={selectedOptions}
          onChange={(e) => setSelectedOptions(e.target.value)}
          displayEmpty
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return placeholder; // Display the default label when no value is selected
            }
            return (
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {selected
                  .map((optionId) => {
                    const option = checkboxOptions
                      .flatMap((group) => group.options)
                      .find((option) => option.uuid === optionId);
                    return option?.label;
                  })
                  .join(", ")}
              </div>
            );
          }}
        >
          <Box sx={{ padding: "0 16px" }}>
            <Grid item spacing={2}>
              {checkboxOptions?.map((group, index) => (
                <React.Fragment key={group.groupName}>
                  {!!group.options?.length && (
                    <FormLabel
                      muiSkipListHighlight
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                        fontSize: "12px",
                        padding: "8px 0px",
                        lineHeight: 1,
                      }}
                    >
                      {group.groupName}
                    </FormLabel>
                  )}
                  {group.options.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      onClick={handleOptionToggle(option)}
                      sx={{
                        padding: 0,
                      }}
                      disabled={option?.disabled}
                    >
                      <Checkbox
                        checked={option?.checked || isOptionSelected(option)}
                        sx={{
                          paddingLeft: 0,
                        }}
                        disabled={option?.disabled}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}

                  {index !== Object.keys(checkboxOptions)?.length - 1 &&
                    !!group.options?.length &&
                    group.showDivider && <Divider />}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Select>
      </Box>
      {errorMessage && (
        <FormHelperText sx={{ margin: "4px 0 0 0" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
