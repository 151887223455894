import { Box, Stack } from "@mui/material";
import React from "react";
import StyledBackground from "../../../components/common/backgrounds/StyledBackground";

import FormAboutToTimeOut from "components/session/aboutToTimeOut/forms/FormAboutToTimeOut";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/svg/logo.svg";

export default function AboutToTimeOut() {
  const navigate = useNavigate();

  const handleLogOut = (e) => {
    navigate("/auth/login");
  };

  const handleStayLogIn = (e) => {
    navigate("/axis");
  };

  const getForm = () => {
    return (
      <FormAboutToTimeOut
        onClickLogOut={handleLogOut}
        onStayLoggedIn={handleStayLogIn}
      />
    );
  };
  return (
    <div>
      <StyledBackground bgcolor="#255097">
        <Stack spacing={10}>
          <img src={Logo} />
          <Box sx={{ width: "100%", maxWidth: 360 }}>{getForm()}</Box>
        </Stack>
      </StyledBackground>
    </div>
  );
}
