import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { withTheme } from "@emotion/react";

import SidebarWeb from "./SidebarWeb";
import SidebarMobile from "./SidebarMobile";

function Sidebar({
  theme,
  items,
  showFooter = true,
  selectedItem,
  ...rest
}) {
  const isWeb = useMediaQuery(theme.breakpoints.up("md"));

  if (isWeb)
    return (
      <SidebarWeb
        items={items}
        showFooter={showFooter}
        selectedItem={selectedItem}
        {...rest}
      />
    );
  return (
    <SidebarMobile
      items={items}
      showFooter={showFooter}
      selectedItem={selectedItem}
      {...rest}
    />
  );
}

export default withTheme(Sidebar);
