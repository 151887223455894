import {
  apiDeleteLocation,
  apiDeleteSubsidiary,
  apiSignUpStep1LocationUpdate,
} from "apis/auth";
import useAuth from "hooks/useAuth";
import useBackdropProgress from "hooks/useBackdropProgress";
import useSnackbar from "hooks/useSnackbar";
import Form1SignUpStep1 from "layouts/auth/signUp/step1/forms/Form1";
import Form2SignUpStep1 from "layouts/auth/signUp/step1/forms/Form2";
import Form3SignUpStep1 from "layouts/auth/signUp/step1/forms/Form3";
import Form4SignUpStep1 from "layouts/auth/signUp/step1/forms/Form4";
import Form5SignUpStep1 from "layouts/auth/signUp/step1/forms/Form5";
import Form6SignUpStep1 from "layouts/auth/signUp/step1/forms/Form6";
import Form7SignUpStep1 from "layouts/auth/signUp/step1/forms/Form7";
import Form8SignUpStep1 from "layouts/auth/signUp/step1/forms/Form8";
import StepperSignup from "layouts/auth/signUp/stepper/StepperSignup";
import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentUserConfig } from "redux/slices/common";
import { getSession } from "utils/jwt";
import { setSession } from "utils/jwt";
import { useNavigate } from "react-router-dom";

const STEPS = {
  GETTING_STARTED: 1,
  GROUP: 2,
  SUBSIDIARIES: 3,
  LOCATION: 4,
  SUMMARY: 5,
};

const FORM_STEPS = {
  CHECKLIST: 0,
  PERSONAL_DETAILS: 1,
  GROUP: 2,
  SUBSIDIARIES: 3,
  LOCATION_DETAILS: 4,
  LOCATION_UPDATE: 5,
  SUMMARY: 6,
  SUCCESS: 7,
};

const TABS_GROUPS = {
  [STEPS.GETTING_STARTED]: [FORM_STEPS.CHECKLIST, FORM_STEPS.PERSONAL_DETAILS],
  [STEPS.GROUP]: [FORM_STEPS.GROUP],
  [STEPS.SUBSIDIARIES]: [FORM_STEPS.SUBSIDIARIES],
  [STEPS.LOCATION]: [FORM_STEPS.LOCATION_DETAILS, FORM_STEPS.LOCATION_UPDATE],
  [STEPS.SUMMARY]: [FORM_STEPS.SUMMARY, FORM_STEPS.SUCCESS],
};

const list = [
  {
    index: STEPS.GETTING_STARTED,
    label: "Getting Started",
  },
  {
    index: STEPS.GROUP,
    label: "Group",
  },
  {
    index: STEPS.SUBSIDIARIES,
    label: "Subsidiaries",
  },
  {
    index: STEPS.LOCATION,
    label: "Location",
  },
  {
    index: STEPS.SUMMARY,
    label: "Summary",
  },
];

export default function CompSignUpStep1() {
  const currentUserConfig = getSession();

  const [formStep, setFormStep] = React.useState(
    parseInt(currentUserConfig?.step) || FORM_STEPS.CHECKLIST
  );

  const getActiveStep = () => {
    let activeStep = STEPS.GETTING_STARTED;
    Object.keys(TABS_GROUPS).map((item) => {
      if (TABS_GROUPS[item].indexOf(parseInt(currentUserConfig?.step)) != -1) {
        activeStep = item;
      }
    });
    return activeStep;
  };

  const [step, setStep] = React.useState(getActiveStep());

  const {
    logInSubmit,
    signUpStep1Step1,
    signUpStep1Step2,
    signUpStep1Step3,
    signUpStep1Step4,
  } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { toggleBackdropProgress } = useBackdropProgress();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setFormStepData = (step, formStep) => {
    setStep(step);
    setFormStep(formStep);
    let currentUser = getSession();
    currentUser.step = formStep;
    setSession({ currentUser });
  };

  const syncReduxAndLS = (data) => {
    dispatch(setCurrentUserConfig(data));
    setSession({ currentUser: data });
  };

  const handleSubmitFormStep1 = (e) => {
    setFormStepData(STEPS.GETTING_STARTED, FORM_STEPS.PERSONAL_DETAILS);
  };

  const handleSubmitFormStep2 = async (formData) => {
    // Please update form data if changed {firstName, lastName, email, mobileNumber, password}
    try {
      toggleBackdropProgress(true);
      await signUpStep1Step1(formData);
      const loginResponse = await logInSubmit(
        formData?.email,
        formData?.password
      );
      syncReduxAndLS({
        ...loginResponse?.current_user,
      });

      toggleBackdropProgress(false);
      setFormStepData(STEPS.GROUP, FORM_STEPS.GROUP);
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleSubmitFormStep3 = async (formData) => {
    // Please update form data if changed { name, iso, haveSubsideries }
    try {
      toggleBackdropProgress(true);
      const groupData = await signUpStep1Step2({
        ...formData,
        currentUserConfig,
      });
      syncReduxAndLS({
        ...currentUserConfig,
        group: {
          name: groupData?.response_data?.[0]?.name,
          id: groupData?.response_data?.[0]?.id,
          uuid: groupData?.response_data?.[0]?.uuid,
          subsidiaries_yes_no: formData?.haveSubsideries == "yes" ? 1 : 0,
          address: groupData?.response_data?.[0]?.address,
        },
      });
      toggleBackdropProgress(false);

      if (formData?.haveSubsideries?.toLowerCase() == "yes") {
        setFormStepData(STEPS.SUBSIDIARIES, FORM_STEPS.SUBSIDIARIES);
      } else {
        setFormStepData(STEPS.LOCATION, FORM_STEPS.LOCATION_DETAILS);
      }
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleSubmitFormStep4 = async (formData) => {
    // Please update form data if changed { subsidiaries: values }
    try {
      toggleBackdropProgress(true);
      const subsidiariesData = await signUpStep1Step3({
        ...formData,
        currentUserConfig,
      });

      const loginSubsidiariesData = subsidiariesData?.response_data.map(
        (item) => ({
          name: item?.name,
          id: item?.id,
          uuid: item?.uuid,
          address__country_code: item?.address[0]?.country_code || null,
        })
      );

      syncReduxAndLS({
        ...currentUserConfig,
        subsidiaries: loginSubsidiariesData,
      });
      toggleBackdropProgress(false);

      setFormStepData(STEPS.LOCATION, FORM_STEPS.LOCATION_DETAILS);
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleDeleteSubsidiaryFormStep4 = async (uuid, callback) => {
    try {
      toggleBackdropProgress(true);
      await apiDeleteSubsidiary({
        uuid: uuid,
      });
      syncReduxAndLS({
        ...currentUserConfig,
        subsidiaries: currentUserConfig.subsidiaries?.filter(
          (subsidiaries) => subsidiaries.uuid != uuid
        ),
      });
      toggleBackdropProgress(false);
      callback();
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleDeleteLocationFormStep5 = async (uuid, callback) => {
    try {
      toggleBackdropProgress(true);
      await apiDeleteLocation({
        uuid: uuid,
      });
      syncReduxAndLS({
        ...currentUserConfig,
        locations: currentUserConfig.locations?.filter(
          (locations) => locations.uuid != uuid
        ),
      });
      toggleBackdropProgress(false);
      callback();
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleSubmitFormStep5 = async (formData) => {
    try {
      toggleBackdropProgress(true);
      const locationsData = await signUpStep1Step4({
        ...formData,
        currentUserConfig,
      });
      toggleBackdropProgress(false);

      const loginLocationsData = locationsData?.response_data?.map(
        (item, index) => {
          return {
            name: item?.name,
            id: item?.id,
            uuid: item?.uuid,
            location_type: formData?.locations?.[index]?.location_type,
            location_subsidiary: item?.location_subsidiary,
            ...item?.address?.[0],
          };
        }
      );

      syncReduxAndLS({
        ...currentUserConfig,
        locations: loginLocationsData,
      });

      setFormStepData(STEPS.LOCATION, FORM_STEPS.LOCATION_UPDATE);
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleSubmitFormStep6 = async (formData) => {
    // Please update form data if changed { locationId, uuid, callback }
    try {
      toggleBackdropProgress(true);
      const updatedLocationsData = await apiSignUpStep1LocationUpdate(formData);
      syncReduxAndLS({
        ...currentUserConfig,
        locations: currentUserConfig?.locations?.map((item) => {
          return {
            ...item,
            location_subsidiary:
              item?.uuid == formData?.locationId
                ? updatedLocationsData?.data?.relationships?.location_subsidiary
                    ?.data?.meta?.drupal_internal__target_id
                : item?.location_subsidiary,
          };
        }),
      });
      formData.callback();
      toggleBackdropProgress(false);
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.errors?.[0]?.detail || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleNextFormStep6 = () => {
    setFormStepData(STEPS.SUMMARY, FORM_STEPS.SUMMARY);
  };

  const handleSubmitFormStep7 = (e) => {
    setFormStepData(STEPS.SUMMARY, FORM_STEPS.SUCCESS);
  };

  const handleSubmitFormStep8 = (e) => {
    // navigate("/axis");
    // window.location.reload();
  };

  const handleChangeStep = (index) => {
    if (index === 1) {
      setStep(STEPS.GETTING_STARTED);
      setFormStep(FORM_STEPS.PERSONAL_DETAILS);
    } else if (index === 2) {
      setStep(STEPS.GROUP);
      setFormStep(FORM_STEPS.GROUP);
    } else if (index === 3 && currentUserConfig?.group?.subsidiaries_yes_no) {
      setStep(STEPS.SUBSIDIARIES);
      setFormStep(FORM_STEPS.SUBSIDIARIES);
    } else if (index === 4) {
      setStep(STEPS.LOCATION);
      setFormStep(FORM_STEPS.LOCATION_DETAILS);
    } else if (index === 5) {
      setStep(STEPS.SUMMARY);
      setFormStep(FORM_STEPS.SUMMARY);
    }
  };

  const showForm = () => {
    switch (formStep) {
      case FORM_STEPS.CHECKLIST:
        return <Form1SignUpStep1 onSubmit={handleSubmitFormStep1} />;
      case FORM_STEPS.PERSONAL_DETAILS:
        return <Form2SignUpStep1 onSubmit={handleSubmitFormStep2} />;
      case FORM_STEPS.GROUP:
        return (
          <Form3SignUpStep1
            defaultValues={currentUserConfig}
            onSubmit={handleSubmitFormStep3}
          />
        );
      case FORM_STEPS.SUBSIDIARIES:
        return (
          <Form4SignUpStep1
            defaultValues={currentUserConfig}
            onSubmit={handleSubmitFormStep4}
            onDeleteSubsidiary={handleDeleteSubsidiaryFormStep4}
          />
        );
      case FORM_STEPS.LOCATION_DETAILS:
        return (
          <Form5SignUpStep1
            defaultValues={currentUserConfig}
            onSubmit={handleSubmitFormStep5}
            onDeleteLocation={handleDeleteLocationFormStep5}
          />
        );
      case FORM_STEPS.LOCATION_UPDATE:
        return (
          <Form6SignUpStep1
            defaultValues={currentUserConfig}
            onSubmit={handleSubmitFormStep6}
            onNext={handleNextFormStep6}
          />
        );
      case FORM_STEPS.SUMMARY:
        return (
          <Form7SignUpStep1
            defaultValues={currentUserConfig}
            onSubmit={handleSubmitFormStep7}
          />
        );
      case FORM_STEPS.SUCCESS:
        return <Form8SignUpStep1 onSubmit={handleSubmitFormStep8} />;
    }
  };
  return (
    <div>
      <StepperSignup
        activeStep={step}
        list={list}
        onChangeStep={handleChangeStep}
      >
        {showForm()}
      </StepperSignup>
    </div>
  );
}
