import styled from "@emotion/styled";
import { Divider, Drawer as MuiDrawer } from "@mui/material";

import SidebarNav from "./SidebarNav";

import useAuth from "hooks/useAuth";
import Logo from "../../assets/svg/logo.svg";
import Menu from "../../assets/svg/menu.svg";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SidebarMobile = ({
  items,
  showFooter = true,
  onClose,
  selectedItem,
  ...rest
}) => {

  return (
    <Drawer variant="permanent" onClose={onClose} {...rest}>
      <DrawerHeader>
        <img src={Logo} style={{ marginRight: 60 }} width={120} />
        <img
          src={Menu}
          onClick={onClose}
          style={{ marginRight: 22, cursor: "pointer" }}
        />
      </DrawerHeader>
      <Divider />
      <SidebarNav
        items={items}
        selectedItem={selectedItem}
      />
    </Drawer>
  );
};

export default SidebarMobile;
