import Form1SignUpStep2 from "layouts/auth/signUp/step2/forms/Form1";
import Form2SignUpStep2 from "layouts/auth/signUp/step2/forms/Form2";
import Form3SignUpStep2 from "layouts/auth/signUp/step2/forms/Form3";
import Form4SignUpStep2 from "layouts/auth/signUp/step2/forms/Form4";
import Form5SignUpStep2 from "layouts/auth/signUp/step2/forms/Form5";
import Form6SignUpStep2 from "layouts/auth/signUp/step2/forms/Form6";
import Form7SignUpStep2 from "layouts/auth/signUp/step2/forms/Form7";
import Form8SignUpStep2 from "layouts/auth/signUp/step2/forms/Form8";
import Form9SignUpStep2 from "layouts/auth/signUp/step2/forms/Form9";
import Form10SignUpStep2 from "layouts/auth/signUp/step2/forms/Form10";
import Form11SignUpStep2 from "layouts/auth/signUp/step2/forms/Form11";
import Form12SignUpStep2 from "layouts/auth/signUp/step2/forms/Form12";
import Form13SignUpStep2 from "layouts/auth/signUp/step2/forms/Form13";
import React from "react";

export default function CompSignUpStep2({ onChangeForm }) {
  const [formStep, setFormStep] = React.useState(1);

  React.useEffect(() => {
    onChangeForm(formStep);
  }, [formStep]);

  const handleSubmitFormStep1 = (e) => {
    setFormStep(2);
  };

  const handleSubmitFormStep2 = (e) => {
    setFormStep(3);
  };

  const handleSubmitFormStep3 = (e) => {
    setFormStep(4);
  };

  const handleSubmitFormStep4 = (e) => {
    setFormStep(5);
  };

  const handleSubmitFormStep5 = (e) => {
    setFormStep(6);
  };

  const handleSubmitFormStep6 = (e) => {
    setFormStep(7);
  };

  const handleSubmitFormStep7 = (e) => {
    setFormStep(8);
  };

  const handleSubmitFormStep8 = (e) => {
    setFormStep(9);
  };

  const handleSubmitFormStep9 = (e) => {
    setFormStep(10);
  };

  const handleSubmitFormStep10 = (e) => {
    setFormStep(11);
  };

  const handleSubmitFormStep11 = (e) => {
    setFormStep(12);
  };

  const handleSubmitFormStep12 = (e) => {
    setFormStep(13);
  };

  const handleSubmitFormStep13 = (e) => {};

  const showForm = () => {
    if (formStep === 1) {
      return <Form1SignUpStep2 onSubmit={handleSubmitFormStep1} />;
    } else if (formStep === 2) {
      return <Form2SignUpStep2 onSubmit={handleSubmitFormStep2} />;
    } else if (formStep === 3) {
      return <Form3SignUpStep2 onSubmit={handleSubmitFormStep3} />;
    } else if (formStep === 4) {
      return <Form4SignUpStep2 onSubmit={handleSubmitFormStep4} />;
    } else if (formStep === 5) {
      return <Form5SignUpStep2 onSubmit={handleSubmitFormStep5} />;
    } else if (formStep === 6) {
      return <Form6SignUpStep2 onSubmit={handleSubmitFormStep6} />;
    } else if (formStep === 7) {
      return <Form7SignUpStep2 onSubmit={handleSubmitFormStep7} />;
    } else if (formStep === 8) {
      return <Form8SignUpStep2 onSubmit={handleSubmitFormStep8} />;
    } else if (formStep === 9) {
      return <Form9SignUpStep2 onSubmit={handleSubmitFormStep9} />;
    } else if (formStep === 10) {
      return <Form10SignUpStep2 onSubmit={handleSubmitFormStep10} />;
    } else if (formStep === 11) {
      return <Form11SignUpStep2 onSubmit={handleSubmitFormStep11} />;
    } else if (formStep === 12) {
      return <Form12SignUpStep2 onSubmit={handleSubmitFormStep12} />;
    } else {
      return <Form13SignUpStep2 onSubmit={handleSubmitFormStep13} />;
    }
  };

  return <div>{showForm()}</div>;
}
