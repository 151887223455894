import Form1SignUpStep3 from "layouts/auth/signUp/step3/forms/Form1";
import Form2SignUpStep3 from "layouts/auth/signUp/step3/forms/Form2";
import React from "react";

export default function CompSignUpStep3({ onChangeForm }) {
  const [formStep, setFormStep] = React.useState(1);

  React.useEffect(() => {
    onChangeForm(formStep);
  }, [formStep]);

  const handleSubmitFormStep1 = (e) => {
    setFormStep(2);
  };

  const handleSubmitFormStep2 = (e) => {
  };

  const showForm = () => {
    if (formStep === 1) {
      return <Form1SignUpStep3 onSubmit={handleSubmitFormStep1} />;
    } else {
      return <Form2SignUpStep3 onSubmit={handleSubmitFormStep2} />;
    }
  };

  return <div>{showForm()}</div>;
}
