import { useApiGetImportStatus } from "hooks/api/common";
import React, { createContext, useEffect } from "react";
import FormSuccess from "layouts/forms/importDataFiles/FormSuccess";
import MyDialog from "components/ui/views/MyDlalog";
import { DialogContent } from "@mui/material";
import { getNextImportId, setImportDataAsComplete } from "utils/asyncImport";

const ContextAsyncFileImport = createContext(null);

function AsyncFileImportProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [shouldFetch, setShouldFetch] = React.useState(true);
  const { data, error, isLoading } = useApiGetImportStatus(shouldFetch);

  useEffect(() => {
    setOpen(true);
  }, [data]);

  const handleClose = (e) => {
    setOpen(false);
    let id = getNextImportId();
    let type = data?.[0]?.import_type?.[0]?.value;
    if (type == "axis_transaction_import") {
      setImportDataAsComplete("TRANSACTION", id);
    } else if (type == "axis_supplier_import") {
      setImportDataAsComplete("SUPPLIER", id);
    }
  };

  return (
    <ContextAsyncFileImport.Provider value={{}}>
      {children}
      {!!data && data?.length && (
        <MyDialog sx={{ zIndex: 1200 }} open={open} onClose={handleClose}>
          <DialogContent>
            <FormSuccess data={data} handleClose={handleClose} />
          </DialogContent>
        </MyDialog>
      )}
    </ContextAsyncFileImport.Provider>
  );
}

export { ContextAsyncFileImport, AsyncFileImportProvider };
