import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { HelpCircle } from "react-feather";
import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import SustainableStartup from "../forms/SustainableStartup";
import useAuth from "hooks/useAuth";
import { useDispatch } from "react-redux";
import { setSstDropdown } from "redux/slices/common";
import moment from "moment";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { currentUser } = useAuth();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Stack direction="row" alignItems="center" width="100%">
            <IconButton
              sx={{ display: { xs: "block", md: "none" } }}
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>

            <Stack
              direction={"row"}
              spacing={2}
              alignItems="center"
              flexGrow={1}
            >
              {/* <HelpCircle color={theme.palette.primary.main} /> */}
              <Stack
                direction={"row"}
                spacing={1}
                alignItems="center"
                flexWrap={"wrap"}
                paddingLeft={5}
              >
                <Typography variant="h6">{moment().format('MMMM DD')}</Typography>
                <Typography>{moment().format('YYYY')}</Typography>
              </Stack>
            </Stack>

            <Stack direction={"row"} spacing={4} alignItems="center">
              <SustainableStartup
                // multiselect={true}
                // onePerCategory={true}
                placeholder={currentUser?.group?.name}
                selectSx={{
                  maxWidth: "200px",
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                selectSxPaperProps={{ minWidth: "250px" }}
                // restrict={["group"]}
                onChangeData={(data) => {
                  dispatch(setSstDropdown(data?.value));
                }}
              />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
