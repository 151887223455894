import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";

function MyCheckbox({
  label,
  list,
  checkedValues,
  changeHandler,
  errorMessage,
  sx,
}) {
  const handleChange = (event) => {
    if (changeHandler) {
      changeHandler(event);
    }
  };

  return (
    <FormControl variant="standard" sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormGroup>
        {list.map((item) => (
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox
                checked={
                  checkedValues
                    ? checkedValues.includes(item.value)
                    : item?.checked
                }
                onChange={handleChange}
                name={item?.value}
                disabled={item?.disabled}
              />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default MyCheckbox;
