import { Grid, Typography as MuiTypography, Button } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const Title = styled(MuiTypography)`
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 112%;
  text-align: center;
  margin-bottom: 2rem;
`;

const Description = styled(MuiTypography)`
  font-family: SofiaProLight;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 128%;
`;

export default function Form1SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Assessing your Axis Checklist:</Title>
        </Grid>
        <Grid item xs={12}>
          <Description>To get a scope on your scopes please have:</Description>
          <ul>
            <li>
              <Description>
                Record of all full time employees The electricity provider for
                each location
              </Description>
            </li>
            <li>
              <Description>
                (If your company has fleets) The fuel card provider for each
                fleet
              </Description>
            </li>
            <li>
              <Description>
                The cloud computing provider for each subsidiary
              </Description>
            </li>
            <li>
              <Description>
                (If your company uses a travel agent) The travel agent for each
                subsidiary
              </Description>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleSubmit}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
