import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormHelperText, Grid } from "@mui/material";

export default function MyRadioGroup({
  label,
  labelSx,
  row = false,
  labelSide = false,
  list,
  errorMessage,
  required = true,
  value = "",
  color = "primary",
  ...props
}) {
  return (
    <FormControl error={Boolean(errorMessage)}>
      <Grid
        sx={
          labelSide
            ? { display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }
            : { textAlign: "left" }
        }
      >
        {label && (
          <FormLabel shrink={true}>
            {label} {required ? "*" : undefined}
          </FormLabel>
        )}
        <RadioGroup row={row} defaultValue={value} value={value} {...props}>
          {list.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              label={item.label}
              disabled={item.disabled}
              sx={{...labelSx}}
              control={<Radio color={color} />}
            />
          ))}
        </RadioGroup>
      </Grid>
      {errorMessage && (
        <FormHelperText sx={{ margin: "4px 0 0 0" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
