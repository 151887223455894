import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import MySelect from "components/ui/inputs/MySelect";
import MyTextField from "components/ui/inputs/MyTextField";
import { isoCodes } from "constants/MenuListItems";
import React from "react";
import { setMenuListFirstItem } from "utils/common";
import CrossIcon from "assets/svg/cross.svg";
import { cloneDeep } from "lodash";

export default function Form4SignUpStep1({
  onSubmit,
  onDeleteSubsidiary,
  defaultValues,
}) {
  const [values, setValues] = React.useState(
    (defaultValues?.subsidiaries?.length && defaultValues?.subsidiaries) || [
      {
        name: "",
        address__country_code: "undefined",
        id: undefined,
        uuid: undefined,
      },
    ]
  );

  const [errorValues, setErrorValues] = React.useState(
    defaultValues?.subsidiaries?.length > 0
      ? defaultValues?.subsidiaries.map((item) => {
          return { name: undefined, address__country_code: undefined };
        })
      : [{ name: undefined, address__country_code: undefined }]
  );

  const handleSubmit = (e) => {
    let flag = false;

    let err = [...errorValues];

    for (let i = 0; i < values.length; i++) {
      if (values[i].name.length === 0) {
        err[i].name = "Company type is required";
        flag = true;
      }

      if (values[i].address__country_code === "undefined") {
        err[i].address__country_code = "ISO code is required";
        flag = true;
      }
    }

    if (flag) {
      setErrorValues(err);
      return;
    }
    onSubmit({ subsidiaries: values });
  };

  const addRow = () => {
    let v = [...values];
    v.push({ name: "", address__country_code: "undefined" });
    setValues(v);

    let err = [...errorValues];
    err.push({ name: undefined, address__country_code: undefined });
    setErrorValues(err);
  };

  const handleChangeCompanyType = (e, index) => {
    if (errorValues[index].name) {
      let err = [...errorValues];
      err[index].name = undefined;
      setErrorValues(err);
    }
    let v = [...values];
    v[index].name = e.target.value;
    setValues(v);
  };

  const handleChangeIsoCode = (e, index) => {
    if (errorValues[index].address__country_code) {
      let err = [...errorValues];
      err[index].address__country_code = undefined;
      setErrorValues(err);
    }
    let v = [...values];
    v[index].address__country_code = e.target.value;
    setValues(v);
  };

  const handleDeleteSubsidiary = (index) => {
    let callback = () => {
      let valuesClone = cloneDeep(values);
      valuesClone.splice(index, 1);
      setValues(valuesClone);
    };
    onDeleteSubsidiary(values[index]?.uuid, callback);
  };

  const rowInput = (index) => {
    return (
      <>
        <Grid item xs={12} sm={6} md={7} display={"flex"} alignItems={"center"}>
          {values.length > 1 && (
            <Box
              onClick={() => handleDeleteSubsidiary(index)}
              sx={{
                cursor: "pointer",
                marginRight: "10px",
                position: "relative",
              }}
            >
              <img src={CrossIcon} />
            </Box>
          )}
          <MyTextField
            value={values[index].name}
            placeholder="Subsidiary Name"
            errorMessage={errorValues[index].name}
            onChange={(e) => handleChangeCompanyType(e, index)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Box>
            <MySelect
              errorMessage={errorValues[index].address__country_code}
              menuList={setMenuListFirstItem(isoCodes, null, {
                label: "Country of Registration",
                hide: true,
              })}
              value={values[index].address__country_code}
              onChange={(e) => handleChangeIsoCode(e, index)}
            />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography fontWeight={"bold"} ml={values.length > 1 ? 5 : 0}>
            Please list all subsidiaries and their country of registration.
          </Typography>
        </Grid>
        {values.map((item, index) => rowInput(index))}
        <Grid item xs={12} display="flex" justifyContent="center">
          <Stack
            direction="row"
            spacing={4}
            justifyContent="center"
            width="80%"
          >
            <Stack direction="column" flexGrow={1}>
              <Button
                variant="outlined"
                onClick={addRow}
                sx={{ mt: 4 }}
                style={{
                  border: "2px solid",
                  fontWeight: "bold",
                }}
                fullWidth
              >
                Add another?
              </Button>
            </Stack>
            <Stack direction="column" flexGrow={1}>
              <Button
                variant="outlined"
                onClick={handleSubmit}
                sx={{ mt: 4, width: "100%" }}
                style={{
                  border: "2px solid",
                  fontWeight: "bold",
                }}
                fullWidth
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
