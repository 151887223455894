import styled from "@emotion/styled";
import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";
import React from "react";

import MySelect from "components/ui/inputs/MySelect";
import MyRadioGroup from "components/ui/inputs/MyRadioGroup";
import { isoCodes } from "constants/MenuListItems";
import { setMenuListFirstItem } from "utils/common";

export default function Form3SignUpStep1({ onSubmit, defaultValues }) {
  const [name, setName] = React.useState(defaultValues?.group?.name || "");
  const [iso, setIso] = React.useState(
    defaultValues?.group?.address?.[0]?.country_code || "undefined"
  );
  const [haveSubsideries, setHaveSubsideries] = React.useState(
    defaultValues?.group?.subsidiaries_yes_no ? "yes" : "no" || undefined
  );

  const [errorName, setErrorName] = React.useState(undefined);
  const [errorIso, setErrorIso] = React.useState(undefined);
  const [errorHaveSubsideries, setErrorHaveSubsideries] =
    React.useState(undefined);

  const handleSubmit = (e) => {
    let flag = false;
    if (name.length === 0) {
      setErrorName("Name is required");
      flag = true;
    }

    if (iso === undefined) {
      setErrorIso("Country ISO code is reuired");
      flag = true;
    }

    if (haveSubsideries === undefined) {
      setErrorHaveSubsideries("This field is required");
      flag = true;
    }

    if (flag) return;

    onSubmit({
      name,
      iso,
      haveSubsideries,
      id: defaultValues?.group?.id,
      uuid: defaultValues?.group?.uuid,
    });
  };

  const handleChangeIso = (e) => {
    setErrorIso(undefined);
    setIso(e.target.value);
  };

  const handleChangeName = (e) => {
    setErrorName(undefined);
    setName(e.target.value);
  };

  const handleChangeHaveSubsideries = (e) => {
    setErrorHaveSubsideries(undefined);
    setHaveSubsideries(e.target.value);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MySelect
            value={iso}
            onChange={handleChangeIso}
            label="What country is this company registered in?"
            errorMessage={errorIso}
            menuList={setMenuListFirstItem(isoCodes, null, {
              hide: true,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <MyTextField
            onChange={handleChangeName}
            label="What is the legal name of your business?"
            errorMessage={errorName}
            value={name}
            placeholder="Company Name"
          />
        </Grid>

        <Grid item xs={12}>
          <MyRadioGroup
            onChange={handleChangeHaveSubsideries}
            label="Does this company have any subsidiaries?"
            errorMessage={errorHaveSubsideries}
            row
            value={haveSubsideries}
            list={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
