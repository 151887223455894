import styled from "@emotion/styled";
import {
  Button, DialogContent, DialogTitle, Grid,
  Typography as MuiTypography
} from "@mui/material";
import MyDialog from "components/ui/views/MyDlalog";
import React from "react";
import FormImportDataFilesSignUpStep3 from "../ImportDataFiles";

const Title = styled(MuiTypography)`
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 112%;
  text-align: center;
  margin-bottom: 2rem;
`;

export default function Form1SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  const [open, setOpen] = React.useState(false);
  // const [step, setStep] = React.useState(0);

  // const { showSnackbar } = useSnackbar();
  // const { logOut } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <MuiTypography fontWeight="bold" textAlign="center" maxWidth={400}>
            Please download and fill out our Supplier Import file Template and
            import a CSV file here
          </MuiTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleClickOpen}
            style={{
              // border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Import Now
          </Button>
        </Grid>
      </Grid>

      <MyDialog sx={{ zIndex: 1200 }} open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Import Data Files</DialogTitle>
        <DialogContent>
          <FormImportDataFilesSignUpStep3 onClickNext={handleSubmit} />
        </DialogContent>
      </MyDialog>
    </div>
  );
}
