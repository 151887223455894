import { removeItemOnce } from "./common";

const storeAsyncImportDataToLS = (type, data) => {
  let asyncImportsData;
  try {
    asyncImportsData = JSON.parse(localStorage.getItem("async_imports") || {});
  } catch (e) {
    asyncImportsData = {};
  }

  if (!asyncImportsData.hasOwnProperty(type)) {
    asyncImportsData[type] = [];
  }
  asyncImportsData[type].push(data?.feed_id);
  localStorage.setItem("async_imports", JSON.stringify(asyncImportsData));
};

const setImportDataAsComplete = (type, id) => {
  let asyncImportsData;
  try {
    asyncImportsData = JSON.parse(localStorage.getItem("async_imports") || {});
  } catch (e) {
    asyncImportsData = {};
  }

  if (asyncImportsData.hasOwnProperty(type)) {
    asyncImportsData[type] = removeItemOnce(asyncImportsData[type], id);
    if (asyncImportsData?.[type]?.length == 0) {
      delete asyncImportsData[type];
    }
    localStorage.setItem("async_imports", JSON.stringify(asyncImportsData));
  }
};

const getNextImportId = () => {
  let asyncImportsData;
  try {
    asyncImportsData = JSON.parse(localStorage.getItem("async_imports") || {});
  } catch (e) {
    asyncImportsData = {};
  }

  let nextKey;
  Object.keys(asyncImportsData).map(function (key) {
    if (!nextKey) {
      nextKey = asyncImportsData[key]?.[0];
    }
  });

  return nextKey;
};

const deleteAsyncImportDataToLS = () => {
  localStorage.removeItem("async_imports");
};

export {
  storeAsyncImportDataToLS,
  setImportDataAsComplete,
  deleteAsyncImportDataToLS,
  getNextImportId,
};
