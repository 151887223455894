import { blue } from "@mui/material/colors";
import colors from "./colors";

import SofiaPro from "./fonts/SofiaPro.woff2";
import SofiaProLight from "./fonts/SofiaPro-Light.woff2";
import SofiaProMedium from "./fonts/SofiaPro-Medium.woff2";
import SofiaProSemiBold from "./fonts/SofiaPro-SemiBold.woff2";

const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'SofiaProLight';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('SofiaProLight'), local('SofiaProLight'), url(${SofiaProLight}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      };
      @font-face {
        font-family: 'SofiaProMedium';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('SofiaProMedium'), local('SofiaProMedium'), url(${SofiaProMedium}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      };
      @font-face {
        font-family: 'SofiaProSemiBold';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('SofiaProSemiBold'), local('SofiaProSemiBold'), url(${SofiaProSemiBold}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      };
      @font-face {
        font-family: 'SofiaPro';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('SofiaPro'), local('SofiaPro'), url(${SofiaPro}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      };
    `,
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: "pointer",
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        width: "100%",
        borderRadius: "6px",
        backgroundImage: "none",
        boxShadow: "0px 0px 10px -4px grey",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        "&:last-child": {
          paddingBottom: "16px",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: "small",
      fullWidth: true,
    },
  },
  MuiDialog: {
    defaultProps: {
      scroll: "body",
      PaperProps: {
        style: {
          border: `2px solid ${blue[900]}`,
        },
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      PaperProps: {
        style: {
          border: `2px solid ${blue[900]}`,
          padding: 16,
        },
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      fontSize: 32,
      align: "center",
    },
  },
  MuiDialogContentText: {
    defaultProps: {
      color: blue[900],
      align: "center",
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: blue[900],
        height: 1,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontWeight: "bold",
        fontSize: "12px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      MenuProps: {
        PaperProps: {
          style: {
            border: `2px solid ${colors.primary.main}`,
            overflowY: "auto",
            maxHeight: "16rem",
          },
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getcontentanchorel: null,
      },
    },
    styleOverrides: {
      root: {
        height: "35px",
        "&.MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.border.main,
            transition: "0.3s",
          },
          "&:hover fieldset": {
            borderColor: colors.primary.main,
            transition: "0.3s",
            // background: colors.background.hover,
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.primary.main,
            transition: "0s",
          },
        },
      },
      icon: {
        fill: colors.primary.main,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          height: "35px",
          "& fieldset": {
            borderColor: colors.border.main,
            transition: "0.3s",
          },
          "&:hover fieldset": {
            borderColor: colors.primary.main,
            transition: "0.3s",
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.primary.main,
            transition: "0s",
          },
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: colors.primary.main,
          "&:hover": {
            backgroundColor: colors.primary.main,
          },
        },
        // "&:hover": {
        //   backgroundColor: "orange",
        // },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: "primary",
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: colors.primary.main,
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      variant: "standard",
    },
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeLarge: {
        fontSize: "18px",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          fontFamily: "SofiaProSemiBold",
        },
        color: colors.primary.main,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.primary.main,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.primary.main,
      },
    },
  },
};

export default components;
