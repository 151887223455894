import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import React, { createContext } from "react";
import LoaderGif from "../assets/gif/loader/loader.gif";

const ContextBackdropProgress = createContext(null);

export default function BackdropProgressProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  const toggleBackdropProgress = (value) => {
    if (value) {
      setCounter((c) => c + 1);
      setOpen(true);
    } else {
      if (counter <= 1) {
        setOpen(false);
        setCounter(0);
      } else {
        setOpen(true);
        setCounter((c) => c - 1);
      }
    }
  };

  return (
    <ContextBackdropProgress.Provider value={{ toggleBackdropProgress }}>
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <img src={LoaderGif} width={500} />
      </Backdrop>
    </ContextBackdropProgress.Provider>
  );
}

export { ContextBackdropProgress, BackdropProgressProvider };
