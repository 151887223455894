import { Button, Checkbox, Grid, Link, FormControlLabel } from "@mui/material";
import MyTextField from "../../../../ui/inputs/MyTextField";
import React from "react";
import { validateEmail, validatePassword } from "utils/validation";

export default function FormLogin({ onSubmit, onForgotPassword }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [errorEmail, setErrorEmail] = React.useState(null);
  const [errorPassword, setErrorPassword] = React.useState(null);

  const handleSubmit = (e) => {
    let flag = false;

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      setErrorEmail(emailValidation);
      flag = true;
    }

    const passwordValidation = validatePassword(password);
    if (passwordValidation) {
      setErrorPassword(passwordValidation);
      flag = true;
    }

    if (flag) return;

    onSubmit({ email, password });
  };

  const handleForgotPassword = (e) => {
    onForgotPassword(e);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorEmail(validateEmail(e.target.value));
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorPassword(validatePassword(e.target.value));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyTextField
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            errorMessage={errorEmail}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextField
            label="Password"
            type="password"
            value={password}
            onChange={handleChangePassword}
            errorMessage={errorPassword}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox name="remember" />}
            label="Remember me"
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleSubmit}>
            Log in
          </Button>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Link onClick={handleForgotPassword}>
            Forgotten your username or password?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
