import { Button, Grid, Stack, Typography, Box, Divider } from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";

import MySelect from "components/ui/inputs/MySelect";
import React from "react";
import { apiSignUpStep1GetLocation } from "apis/auth";
import { isoCodes, stateCodes } from "constants/MenuListItems";
import { htmlDecode, setMenuListFirstItem } from "utils/common";
import useBackdropProgress from "hooks/useBackdropProgress";
import CrossIcon from "assets/svg/cross.svg";

export default function Form5SignUpStep1({
  onSubmit,
  onDeleteLocation,
  defaultValues,
}) {
  const [values, setValues] = React.useState(
    (defaultValues?.locations?.length && defaultValues?.locations) || []
  );

  const [locationType, setLocationType] = React.useState("undefined");
  const [locationName, setLocationName] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("undefined");
  const [postalCode, setPostalCode] = React.useState("");
  const [isoCode, setIsoCode] = React.useState("undefined");
  const [selectedLocation, setSelectedLocation] = React.useState(undefined);
  const [locationSubsidiary, setLocationSubsidiary] = React.useState(null);

  const [errorLocationType, setErrorLocationType] = React.useState(undefined);
  const [errorLocationName, setErrorLocationName] = React.useState(undefined);
  const [errorAddressLine1, setErrorAddressLine1] = React.useState(undefined);
  const [errorAddressLine2, setErrorAddressLine2] = React.useState(undefined);
  const [errorCity, setErrorCity] = React.useState(undefined);
  const [errorState, setErrorState] = React.useState(undefined);
  const [errorPostalCode, setErrorPostalCode] = React.useState(undefined);
  const [errorIsoCode, setErrorIsoCode] = React.useState(undefined);

  const [location, setLocation] = React.useState([]);

  const { toggleBackdropProgress } = useBackdropProgress();

  const setLocationList = async () => {
    toggleBackdropProgress(true);
    try {
      const locationListResponse = await apiSignUpStep1GetLocation();
      const locationList = locationListResponse.map(function (item) {
        return {
          ...item,
          label: htmlDecode(item?.name),
          value: item?.tid,
        };
      });
      setMenuListFirstItem(locationList || [], setLocation, {
        hide: true,
      });
    } catch (e) {
      setMenuListFirstItem([], setLocation, {
        hide: true,
      });
    }
    toggleBackdropProgress(false);
  };

  React.useEffect(() => {
    setLocationList();
  }, []);

  const handleFormErrors = () => {
    let flag = false;

    if (locationType === "undefined") {
      setErrorLocationType("Location type is required");
      flag = true;
    }

    if (locationName.length === 0) {
      setErrorLocationName("Location name is required");
      flag = true;
    }

    if (addressLine1.length === 0) {
      setErrorAddressLine1("Address line 1 is required");
      flag = true;
    }

    if (city.length === 0) {
      setErrorCity("City is required");
      flag = true;
    }

    if (isoCode === "US" && state === "undefined") {
      setErrorState("State is required");
      flag = true;
    }

    if (postalCode.length === 0) {
      setErrorPostalCode("Postal code is required");
      flag = true;
    }

    if (isoCode === "undefined") {
      setErrorIsoCode("ISO code is required");
      flag = true;
    }

    return flag;
  };

  const handleSubmit = (e) => {
    if (handleFormErrors()) return;

    let v = [...values];
    v.push({
      address_line1: addressLine1,
      address_line2: addressLine2,
      administrative_area: state,
      country_code: isoCode,
      locality: city,
      location_subsidiary: locationSubsidiary,
      location_type: locationType,
      name: locationName,
      postal_code: postalCode,
      uuid: selectedLocation?.uuid,
      id: selectedLocation?.id,
    });

    onSubmit({ locations: v });
  };

  const handleAdd = (e) => {
    if (handleFormErrors()) return;

    let v = [...values];
    v.push({
      address_line1: addressLine1,
      address_line2: addressLine2,
      administrative_area: state,
      country_code: isoCode,
      locality: city,
      location_subsidiary: locationSubsidiary,
      location_type: locationType,
      name: locationName,
      postal_code: postalCode,
      uuid: selectedLocation?.uuid,
      id: selectedLocation?.id,
    });
    setValues(v);

    setLocationType("undefined");
    setLocationName("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("undefined");
    setPostalCode("");
    setIsoCode("undefined");
    setSelectedLocation(undefined);
    setLocationSubsidiary(null);
  };

  const handleChangeLocationType = (e) => {
    setErrorLocationType(undefined);
    setLocationType(e.target.value);
  };

  const handleChangeLocationName = (e) => {
    setErrorLocationName(undefined);
    setLocationName(e.target.value);
  };

  const handleChangeAddressLine1 = (e) => {
    setErrorAddressLine1(undefined);
    setAddressLine1(e.target.value);
  };

  const handleChangeAddressLine2 = (e) => {
    setErrorAddressLine2(undefined);
    setAddressLine2(e.target.value);
  };

  const handleChangeCity = (e) => {
    setErrorCity(undefined);
    setCity(e.target.value);
  };

  const handleChangeState = (e) => {
    setErrorState(undefined);
    setState(e.target.value);
  };

  const handleChangePostalCode = (e) => {
    setErrorPostalCode(undefined);
    setPostalCode(e.target.value);
  };

  const handleChangeIsoCode = (e) => {
    setErrorIsoCode(undefined);
    setIsoCode(e.target.value);
    setErrorState(undefined);
    setState("undefined");
  };

  const handleDeleteLocation = () => {
    let callback = () => {
      toggleBackdropProgress(true);

      setTimeout(function () {
        let v = [...values];
        let latestLocation = v.pop();
        setValues(v);

        setLocationType(latestLocation?.location_type || "undefined");
        setLocationName(latestLocation?.name || "");
        setAddressLine1(latestLocation?.address_line1 || "");
        setAddressLine2(latestLocation?.address_line2 || "");
        setCity(latestLocation?.locality || "");
        setState(latestLocation?.administrative_area || "undefined");
        setPostalCode(latestLocation?.postal_code || "");
        setIsoCode(latestLocation?.country_code || "undefined");
        setSelectedLocation(
          { uuid: latestLocation?.uuid, id: latestLocation?.id } || undefined
        );
        setLocationSubsidiary(latestLocation?.location_subsidiary || null);

        setErrorLocationType(undefined);
        setErrorLocationName(undefined);
        setErrorAddressLine1(undefined);
        setErrorAddressLine2(undefined);
        setErrorCity(undefined);
        setErrorState(undefined);
        setErrorPostalCode(undefined);
        setErrorIsoCode(undefined);
        toggleBackdropProgress(false);
      }, 300);
    };
    if (selectedLocation) {
      onDeleteLocation(selectedLocation?.uuid, callback);
    } else {
      callback();
    }
  };

  const getForm = () => {
    return (
      <>
        <Grid item xs={12} sm={6} md={4}>
          {location.length !== 0 && (
            <MySelect
              value={locationType}
              label="Location Type:"
              menuList={location}
              onChange={handleChangeLocationType}
              errorMessage={errorLocationType}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <MyTextField
            value={locationName}
            label="Location Name:"
            placeholder="Name of location"
            onChange={handleChangeLocationName}
            errorMessage={errorLocationName}
          />
        </Grid>

        <Grid item xs={12}>
          <MySelect
            value={isoCode}
            menuList={setMenuListFirstItem(isoCodes, null, {
              hide: true,
            })}
            label="Country"
            onChange={handleChangeIsoCode}
            errorMessage={errorIsoCode}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextField
            value={addressLine1}
            label="Address Line 1"
            placeholder="Address Line 1"
            onChange={handleChangeAddressLine1}
            errorMessage={errorAddressLine1}
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextField
            value={addressLine2}
            label="Address Line 2"
            placeholder="Address Line 2"
            onChange={handleChangeAddressLine2}
            required={false}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <MyTextField
            value={city}
            label="City"
            placeholder="City"
            onChange={handleChangeCity}
            errorMessage={errorCity}
          />
        </Grid>

        {(isoCode === "US" || isoCode === undefined) && (
          <Grid item xs={6} sm={4} md={4}>
            <MySelect
              value={state}
              label="State"
              menuList={stateCodes}
              onChange={handleChangeState}
              errorMessage={errorState}
            />
          </Grid>
        )}

        <Grid item xs={6} sm={4} md={4}>
          <MyTextField
            value={postalCode}
            label="Post Code"
            placeholder="Postal code"
            onChange={handleChangePostalCode}
            errorMessage={errorPostalCode}
          />
        </Grid>
      </>
    );
  };

  const getLocations = React.useMemo(() => {
    return (
      <>
        {values.map((item, index) => {
          return (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: `${item?.name}`,
                  }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                {location.length > 0 && (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: `${
                        location.filter(
                          (i) => i.value == item?.location_type
                        )?.[0]?.label
                      }`,
                    }}
                  />
                )}
              </Grid>
            </>
          );
        })}
      </>
    );
  }, [location, values]);

  return (
    <Stack spacing={4}>
      {values.length > 0 && (
        <>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box width={380}>
              <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Typography fontWeight="bold">Location Name:</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Typography fontWeight="bold">Property Type:</Typography>
                </Grid>

                {getLocations}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid display={"flex"}>
          <Grid>
            {values?.length > 0 && (
              <Box
                onClick={handleDeleteLocation}
                sx={{
                  cursor: "pointer",
                  marginRight: "10px",
                  marginTop: "33px",
                  position: "relative",
                }}
              >
                <img src={CrossIcon} />
              </Box>
            )}
          </Grid>
          <Grid container spacing={4}>
            {getForm()}
            <Grid item xs={12} display="flex" justifyContent="center">
              <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                width="80%"
              >
                <Stack direction="column" flexGrow={1}>
                  <Button
                    variant="outlined"
                    onClick={handleAdd}
                    sx={{ mt: 4 }}
                    style={{
                      border: "2px solid",
                      fontWeight: "bold",
                    }}
                    fullWidth
                  >
                    Add another?
                  </Button>
                </Stack>
                <Stack direction="column" flexGrow={1}>
                  <Button
                    variant="outlined"
                    style={{
                      border: "2px solid",
                      fontWeight: "bold",
                    }}
                    onClick={handleSubmit}
                    sx={{ mt: 4, width: "100%" }}
                    fullWidth
                  >
                    Continue
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
