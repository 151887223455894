import {
  Button,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  Typography,
  Divider,
  TextField as MuiTextField,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const TextField = styled(MuiTextField)`
  // padding: 20px;
`;

export default function FormOtp({ onSubmit }) {
  const [digitOne, setDigitOne] = React.useState("");
  const [digitTwo, setDigitTwo] = React.useState("");
  const [digitThree, setDigitThree] = React.useState("");
  const [digitFour, setDigitFour] = React.useState("");
  const [digitFive, setDigitFive] = React.useState("");
  const [digitSix, setDigitSix] = React.useState("");

  const [disableSubmit, setDisableSubmit] = React.useState(true);

  React.useEffect(() => {
    if (
      !digitOne ||
      !digitTwo ||
      !digitThree ||
      !digitFour ||
      !digitFive ||
      !digitSix
    ) {
      setDisableSubmit(true);
      return;
    }

    setDisableSubmit(false);
  }, [digitOne, digitTwo, digitThree, digitFour, digitFive, digitSix]);

  const handleSubmit = (e) => {
    onSubmit({
      otp: digitOne + digitTwo + digitThree + digitFour + digitFive + digitSix,
    });
  };

  const handleChangeDigitOne = (e) => {
    setDigitOne(e.target.value);
  };

  const handleChangeDigitTwo = (e) => {
    setDigitTwo(e.target.value);
  };

  const handleChangeDigitThree = (e) => {
    setDigitThree(e.target.value);
  };

  const handleChangeDigitFour = (e) => {
    setDigitFour(e.target.value);
  };

  const handleChangeDigitFive = (e) => {
    setDigitFive(e.target.value);
  };

  const handleChangeDigitSix = (e) => {
    setDigitSix(e.target.value);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Enter Your Security Code
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="16px">
            We've sent a 6-digit security code to (+1) 831-456-789
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitOne}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitTwo}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitThree}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitFour}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitFive}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="large"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                onChange={handleChangeDigitSix}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Link>Didn't get your code?</Link>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleSubmit}  disabled={disableSubmit}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
