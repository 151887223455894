import merge from "deepmerge";
import { green, grey, blueGrey, indigo, red, blue } from "@mui/material/colors";
import { THEMES } from "../constants";

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: blue[900],
      contrastText: "#FFF",
      light: "#25509780"
    },
    secondary: {
      main: "#C1D9EE",
      contrastText: "#FFF",
    },
    green: {
      main: green[500],
      A700: green["A700"],
      light: "#C5DEB1",
    },
    green2: {
      main: "#93D500",
    },
    green3: {
      main: "#C5DEB1",
    },
    red: {
      main: "#D50000",
    },
    background: {
      grey: "#f9f8f8",
      default: "#FFF",
      paper: "#FFF",
    },
    message: {
      grey: "#f5f5f5",
      blue: "#e1f5fe",
    },
    gold: "#F6C747",
    blue: "#C1D9EE",
    backgroundBlue: "#F4FAFF",
    scope1: { main: "#C1D9EE" },
    scope2: { main: "#F6C746" },
    scope3: { main: "#C5DEB1" },
  },
  chart: {
    color: {
      blue: {
        shade1: "#C1D9EE",
        shade2: "#cde1f1",
        shade3: "#dae8f5",
      },
      gold: {
        shade1: "#F6C747",
        shade2: "#f9d87f",
        shade3: "#fbe3a3",
      },
      green: {
        shade1: "#C5DEB1",
        shade2: "#d6e8c8",
        shade3: "#e8f2e0",
      },
    },
  },
  header: {
    color: grey[500],
    background: blue[50],
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[900],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: blue[900],
    background: "#FFFFFF",
    header: {
      color: grey[200],
      background: "#FFF",
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#FFF",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: blue[500],
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: blue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: blue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
  },
  sidebar: {
    color: "#FFF",
    background: blue[700],
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: blue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: indigo[700],
    header: {
      color: "#FFF",
      background: indigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: indigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
];

export default variants;
