import { TextField } from "@mui/material";
import React from "react";

export default function InputUploadFile({accept, ...props}) {

  return (
    <TextField
      {...props}
      type="file"
      inputProps={{
        accept,
      }}
    />
  );
}
