import GlobalStyle from "components/GlobalStyle";
import CompSignUpStep1 from "components/auth/signUp/Step1";
import React from "react";

export default function SignUpStep1() {
  return (
    <div>
      <GlobalStyle />
      <CompSignUpStep1 />
    </div>
  );
}
