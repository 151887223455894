import { getAccessToken, getRefreshToken, setTokenData } from "utils/jwt";
import ajax from "./ajax";

export const apiLoginSubmit = ({ email, password }) => {
  return ajax({
    method: "post",
    url: "/user/login?_format=json",
    data: {
      mail: email,
      pass: password,
    },
  });
};
export const apiGetToken = () => {
  var data = new FormData();
  data.append("grant_type", "password");
  data.append("password", "api");
  data.append("username", "api");
  data.append("client_id", "zSGUOqO2-XJxWP0cfoWGUYu_CP-fJSQKDZnx1obEgeY");
  data.append("client_secret", "test");

  return ajax({
    method: "post",
    url: "/oauth/token",
    data: data,
  });
};

export const getTokenFromRefreshToken = () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return apiGetToken();
  }
  var data = new FormData();
  data.append("grant_type", "refresh_token");
  data.append("client_id", "zSGUOqO2-XJxWP0cfoWGUYu_CP-fJSQKDZnx1obEgeY");
  data.append("client_secret", "test");
  data.append("refresh_token", refreshToken);

  return ajax({
    method: "post",
    url: "/oauth/token",
    data: data,
  });
};

export const apiResetPasswordStep1 = ({ email }) =>
  ajax({
    method: "post",
    url: "/user/password?_format=json",
    data: {
      mail: email,
    },
  });

export const apiResetPasswordStep2 = ({ timestamp, account_id, token }) =>
  ajax({
    method: "post",
    url: "/rest/user/pass/sms",
    data: {
      token: token,
      uid: account_id,
      timestamp: timestamp,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const apiSendSMSVerfication = ({ id, otp }) =>
  ajax({
    method: "post",
    url: `/rest/sms/verification`,
    data: {
      uid: id,
      sms_code: otp,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const apiResetPasswordStep3 = ({ password, id }) =>
  ajax({
    method: "patch",
    url: `/jsonapi/user/user/${id}`,
    data: {
      data: {
        type: "user--user",
        id: id,
        attributes: {
          pass: {
            value: password,
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

//////////////////////////////////////////////////////////

export const apiSignUpStep1Step1 = ({
  firstName,
  lastName,
  email,
  mobileNumber,
  password,
}) => {
  return ajax({
    method: "post",
    url: `/jsonapi/user/user`,
    data: {
      data: {
        type: "user--user",
        attributes: {
          f_name: firstName,
          l_name: lastName,
          mail: email,
          name: email,
          field_access: 2,
          status: true,
          mobile_number: mobileNumber,
          pass: {
            value: password,
          },
        },
        relationships: {
          roles: {
            data: {
              type: "user_role--user_role",
              id: "a4530a26-f84f-4f90-a4ae-df58b56bb82f",
            },
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiSignUpStep1Step2 = ({
  name,
  iso,
  currentUserConfig,
  haveSubsideries,
  id,
  uuid,
}) => {
  return ajax({
    method: "post",
    url: `/rest/entity/group`,
    data: [
      {
        id: id,
        uuid: uuid,
        type: "axis_group--axis_group",
        name: name,
        subsidiaries_yes_no: haveSubsideries == "yes" ? 1 : 0,
        address: {
          country_code: iso,
        },
        user_id: currentUserConfig?.uid,
      },
    ],
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiSignUpStep1Step3 = ({ subsidiaries, currentUserConfig }) => {
  const subsidiaryArray = [];

  subsidiaries.map((item) => {
    subsidiaryArray.push({
      type: "axis_subsidiary--axis_subsidiary",
      name: item?.name,
      id: item?.id,
      uuid: item?.uuid,
      address: {
        country_code: item?.address__country_code,
      },
      user_id: currentUserConfig?.uid,
      subsidiary_group: {
        target_id: currentUserConfig?.group?.id,
      },
    });
  });

  return ajax({
    method: "post",
    url: `/rest/entity/subsidiary`,
    data: subsidiaryArray,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteSubsidiary = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_subsidiary/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiSignUpStep1Step4 = ({ currentUserConfig, locations }) => {
  const locationArray = [];

  locations.map((item) => {
    locationArray.push({
      type: "axis_location--axis_location",
      name: item?.name,
      id: item?.id,
      uuid: item?.uuid,
      location_address: {
        country_code: item?.country_code,
        address_line1: item?.address_line1,
        address_line2: item?.address_line2,
        locality: item?.locality,
        postal_code: item?.postal_code,
        administrative_area: item?.administrative_area,
        location_subsidiary: item?.location_subsidiary,
      },
      user_id: currentUserConfig?.uid,
      location_type: {
        target_id: item?.location_type,
      },
      location_group: {
        target_id: currentUserConfig?.group?.id,
      },
    });
  });

  return ajax({
    method: "post",
    url: `/rest/entity/location`,
    data: locationArray,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteLocation = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_location/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiSignUpStep1GetLocation = () =>
  ajax({
    method: "get",
    url: `/rest/term-list?vid=location_type`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const apiSignUpStep1GetSubsidariyList = ({ currentUserConfig }) =>
  ajax({
    method: "get",
    url: `/rest/subsidiary-list?group_id=${currentUserConfig?.group?.id}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const apiSignUpStep1LocationUpdate = ({ locationId, uuid }) =>
  ajax({
    method: "patch",
    url: `/jsonapi/axis_location/${locationId}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    data: {
      data: {
        type: "axis_location--axis_location",
        id: locationId,
        relationships: {
          location_subsidiary: {
            data: uuid
              ? {
                  type: "axis_subsidiary--axis_subsidiary",
                  id: uuid,
                }
              : {},
          },
        },
      },
    },
  });

export const apiSignUpStep1GetSummary = ({
  defaultValues: currentUserConfig,
}) => {
  return ajax({
    method: "get",
    url: `/rest/signup/step-summary?group_id=${currentUserConfig?.group?.id}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
