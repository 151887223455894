import React from "react";
import { Box, Stack } from "@mui/material";
import StyledBackground from "../../../components/common/backgrounds/StyledBackground";
import FormLogin from "../../../components/auth/login/forms/FormLogin";
import FormOtp from "../../../components/auth/login/forms/FormOtp";
import FormWelcome from "../../../components/auth/login/forms/FormWelcome";

import Logo from "../../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useSnackbar from "hooks/useSnackbar";
import useBackdropProgress from "hooks/useBackdropProgress";
import { setCurrentUserConfig } from "redux/slices/common";
import { useDispatch } from "react-redux";
import { isValidToken } from "utils/jwt";

export default function Login() {
  const [form, setForm] = React.useState(0);

  const {
    logInSubmit,
    loginSubmitOtp,
    logInSubmitWelcome,
    logOut,
    isAuthenticated,
    isInitialized,
  } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { toggleBackdropProgress } = useBackdropProgress();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/axis");
    }
  }, [isAuthenticated]);

  const handleForgotPassword = (e) => {
    navigate("/auth/reset-password");
  };

  const handleFormOneSubmit = async ({ email, password }) => {
    try {
      toggleBackdropProgress(true);

      const loginResponse = await logInSubmit(email, password);
      dispatch(
        setCurrentUserConfig({
          ...loginResponse?.current_user,
        })
      );
      toggleBackdropProgress(false);

      if (parseInt(loginResponse?.current_user?.step) === 6) {
        navigate("/axis");
      } else {
        navigate("/auth/sign-up/step-1");
      }
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error?.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleFormTwoSubmit = async (otp) => {
    try {
      await loginSubmitOtp(otp);
      navigate("/axis");
      // setForm(2);
    } catch (error) {
      setForm(2);
      const message = error.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleFormThreeSubmit = async () => {
    try {
      await logInSubmitWelcome();

      navigate("/axis");
    } catch (error) {
      const message = error.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const getForm = () => {
    if (form === 2) {
      return <FormWelcome onSubmit={handleFormThreeSubmit} />;
    } else if (form === 1) {
      return <FormOtp onSubmit={handleFormTwoSubmit} />;
    } else {
      return (
        <FormLogin
          onSubmit={handleFormOneSubmit}
          onForgotPassword={handleForgotPassword}
        />
      );
    }
  };

  if (isValidToken()) {
    return;
  }

  return (
    <div>
      <StyledBackground bgcolor="#F6C746">
        <Stack spacing={10} alignItems="center">
          <img src={Logo} width="80%" />
          <Box sx={{ width: "100%", maxWidth: 360 }}>{getForm()}</Box>
        </Stack>
      </StyledBackground>
    </div>
  );
}
