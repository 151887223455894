import SidebarNav from "./SidebarNav";

import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Logo from "../../assets/svg/logo.svg";
import Menu from "../../assets/svg/menu.svg";
import { Box } from "@mui/material";
import SidebarMobile from "./SidebarMobile";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(22)})`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SidebarWeb({
  items,
  handleDrawer,
  showFooter = true,
  open,
  onClose,
  selectedItem,
  ...rest
}) {
  return (
    <>
      <SidebarMobile
        items={items}
        showFooter={showFooter}
        open={open}
        onClose={onClose}
        selectedItem={selectedItem}
        {...rest}
      />
      <Box height="100vh" width="90px" />
      <Box height="100vh" width="90px" overflowy="auto" position="fixed" zIndex={2}>
        <DrawerHeader>
          <img src={Logo} style={{ marginRight: 60 }} width={120} />
          <img
            src={Menu}
            onClick={onClose}
            style={{ marginRight: 22, cursor: "pointer" }}
          />
        </DrawerHeader>
        <Divider />
        <SidebarNav
          items={items}
          selectedItem={selectedItem}
        />
      </Box>
    </>
  );
}
