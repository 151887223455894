import {
  Button,
  Grid,
  Box,
  Stack,
  Typography,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
  Divider,
} from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";
import styled from "@emotion/styled";
import MySelect from "components/ui/inputs/MySelect";
import MyRadioGroup from "components/ui/inputs/MyRadioGroup";

const TableCell = styled(MuiTableCell)`
  color: ${(props) => props.theme.palette.primary.main};
  border-bottom: none;
`;

export default function Form7SignUpStep1({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Company Name:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Number of Employees:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Travel Agent:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>CPU Computers:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>CarbonPay LTD</TableCell>
                <TableCell>23</TableCell>
                <TableCell>Delgado</TableCell>
                <TableCell>AWS</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CarbonPay INC</TableCell>
                <TableCell>50</TableCell>
                <TableCell>-</TableCell>
                <TableCell>Azure</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CarbonPay BV</TableCell>
                <TableCell>30</TableCell>
                <TableCell>Anio</TableCell>
                <TableCell>GCP</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Divider/>
        </Grid>

        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Locations:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Location Name:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Number of Employees:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Electricity Supplier:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>NY Office</TableCell>
                <TableCell>13</TableCell>
                <TableCell>British Gas</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>LA Office</TableCell>
                <TableCell>10</TableCell>
                <TableCell>Duke Energy</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>London Office</TableCell>
                <TableCell>20</TableCell>
                <TableCell>EDF Energy</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>14/8 Retail Store</TableCell>
                <TableCell>30</TableCell>
                <TableCell>ConEdison</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prince St. Factory</TableCell>
                <TableCell>30</TableCell>
                <TableCell>ConEdison</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
