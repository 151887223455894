import {
  Stack,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  useTheme,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  InputAdornment,
  Grid,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import NoData from "pages/Settings/Sources/ui/NoData";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { getClimatiqApiLabel } from "utils/common";
import { useSelector } from "react-redux";
import MyTextField from "components/ui/inputs/MyTextField";
import NoSearchResults from "pages/Settings/Sources/ui/NoSearchResults/NoSearchResults";

const TableWrapper = styled.div`
  max-height: 700px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCellHead = styled(MuiTableCell)`
  color: ${(props) => props.theme.palette.primary.main};
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  font-family: SofiaPro;
  font-weight: bold;
`;

const TableCell = styled(MuiTableCell)`
  color: ${(props) => props.theme.palette.primary.main};
  border-bottom: none;
`;

export default function Source({
  rowList = [],
  isLoading,
  error,
  handleSearch,
  isFilterApplied,
}) {
  const theme = useTheme();

  const filter = useSelector((state) => state.common.filter);
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );

  return (
    <Card>
      <CardHeader
        title={
          <div>
            <Typography variant="h2">Sources</Typography>
          </div>
        }
      />

      <CardContent sx={{ pt: 0 }}>
        <Grid xs={4}>
          <MyTextField
            value={filter?.[currentPageConfig?.currentPage]?.sources?.name}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon
                    sx={{
                      color: `${theme.palette.primary.main}`,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            onChange={handleSearch}
          />
        </Grid>
        <Stack spacing={4}>
          <Table>
            <TableHead
              sx={{
                borderBottom: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              <TableRow>
                <TableCellHead
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold", width: "30%" }}
                >
                  Supplier:
                </TableCellHead>
                <TableCellHead
                  component="th"
                  align="center"
                  scope="row"
                  sx={{ width: "25%", paddingLeft: 0 }}
                >
                  Source:
                </TableCellHead>
              </TableRow>
            </TableHead>
          </Table>
          <TableWrapper>
            <Table stickyHeader aria-label="sticky table">
              {isLoading || error ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Stack width="100%" spacing={2}>
                        <Skeleton variant="rounded" width="100%" height={60} />
                        <Skeleton variant="rounded" width="100%" height={60} />
                        <Skeleton variant="rounded" width="100%" height={60} />
                        <Skeleton variant="rounded" width="100%" height={60} />
                        <Skeleton variant="rounded" width="100%" height={60} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rowList?.length ? (
                <TableBody>
                  {rowList.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: "bold", width: "30%" }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        align="center"
                        scope="row"
                        sx={{ width: "25%" }}
                      >
                        {getClimatiqApiLabel(row?.climatiq_api)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    {!isFilterApplied ? <NoSearchResults /> : <NoData />}
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableWrapper>
        </Stack>
      </CardContent>
    </Card>
  );
}
