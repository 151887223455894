import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, isProfileComplete } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (isAuthenticated) {
    if (isProfileComplete) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <Navigate to="/auth/sign-up/step-1" />;
    }
  }
}

export default AuthGuard;
