import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const Container = styled(Box)`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MyStyle = styled(Box)`
  border-top: 33vh solid white;
  border-right: 100vw solid transparent;
  height: 66vh;
  width: 0;
`;

const Content = styled(Box)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterCircle = styled(Box)`
  height: 700px;
  width: 700px;
  border-radius: 50%;
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InnerCircle = styled(Box)`
  height: 690px;
  width: 690px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function StyledBackground({ children, bgcolor }) {
  return (
    <Container>
      <OuterCircle bgcolor={bgcolor}>
        <InnerCircle />
      </OuterCircle>
      <Content>{children}</Content>

      <MyStyle bgcolor={bgcolor} />
    </Container>
  );
}
