export const validateEmail = (email) => {
  if (email.length === 0) return "Email is required";
  if (/^\w+([\.-\\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    return null;
  return "Invalid email address!";
};

export const validatePassword = (password) => {
  if (password.length === 0) return "Password is required";
  if (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@£$%^&])[A-Za-z\d@$!%*.?&]{8,}$/.test(
      password
    )
  )
    return null;
  return "Password must be at least 8 characters and must contain an Uppercase, Lowercase, Special Character and Numbers.";
};
