import {
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
} from "@mui/material";
import styled from "@emotion/styled";
import { apiSignUpStep1GetSummary } from "apis/auth";
import React from "react";
import useBackdropProgress from "hooks/useBackdropProgress";

const TableCell = styled(MuiTableCell)`
  color: ${(props) => props.theme.palette.primary.main};
  border-bottom: none;
`;

export default function Form7SignUpStep1({ onSubmit, defaultValues }) {
  const [list, setList] = React.useState([]);
  const { toggleBackdropProgress } = useBackdropProgress();

  React.useEffect(() => {
    (async () => {
      try {
        toggleBackdropProgress(true);
        const data = await apiSignUpStep1GetSummary({ defaultValues });
        setList(data);
        toggleBackdropProgress(false);
      } catch (e) {}
    })();
  }, []);

  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Please check that all the information below is correct:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Company Name:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Company Type:</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Locations:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item) => {
                return (
                  <TableRow>
                    <TableCell>{item.company_name}</TableCell>
                    <TableCell>{item.company_type}</TableCell>
                    <TableCell>{item.count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
