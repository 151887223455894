import {
  apiGetRecordList,
  apiGetTargetList,
  apiGetProjectList,
  apiGetDepartmentList,
  apiGetOffsetList,
  apiGetSourceList,
} from "apis/settings";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { getSession } from "utils/jwt";
import { getSstDropDownData } from "./common";

// Helper Functions.
const getRecordListApi = ({ currentUser, subsidiary_id, location_id }) => {
  let url = `/rest/record-list?group_id=${currentUser?.group?.id}`;

  if (subsidiary_id) {
    url = `${url}&subsidiary_id=${subsidiary_id}`;
  }
  if (location_id) {
    url = `${url}&location_id=${location_id}`;
  }
  return url;
};

const getPayloadLatestRecords = () => {
  const currentUser = getSession();
  return {
    currentUser,
    ...getSstDropDownData(),
  };
};

// Services.
export const useApiGetLatestRecords = (shouldFetch) => {
  const payload = getPayloadLatestRecords();
  return useSWR(
    shouldFetch ? () => getRecordListApi(payload) : null,
    async (url) => apiGetRecordList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

// Helper Functions.
const getTargetListApi = ({ currentUser }) => {
  return `/rest/target-list?group_id=${currentUser?.group?.id}`;
};

// Services.
export const useApiGetLatestTargets = (shouldFetch) => {
  const currentUser = getSession();
  return useSWR(
    shouldFetch ? () => getTargetListApi({ currentUser }) : null,
    async (url) => apiGetTargetList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

// Helper Functions.
const getProjectListApi = ({ currentUser }) => {
  return `/rest/project-list/${currentUser?.group?.id}`;
};

// Services.
export const useApiGetLatestProjects = (shouldFetch) => {
  const currentUser = getSession();
  return useSWR(
    shouldFetch ? () => getProjectListApi({ currentUser }) : null,
    async (url) => apiGetProjectList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

// Helper Functions.
const getDepartmentListApi = ({ currentUser }) => {
  return `/rest/department-list/${currentUser?.group?.id}`;
};

// Services.
export const useApiGetLatestDepartments = (shouldFetch) => {
  const currentUser = getSession();
  return useSWR(
    shouldFetch ? () => getDepartmentListApi({ currentUser }) : null,
    async (url) => apiGetDepartmentList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

// Helper Functions.
const getOffsetListApi = ({ currentUser }) => {
  return `/rest/offset-list?group_id=${currentUser?.group?.id}`;
};

// Services.
export const useApiGetOffsets = (shouldFetch) => {
  const currentUser = getSession();
  return useSWR(
    shouldFetch ? () => getOffsetListApi({ currentUser }) : null,
    async (url) => apiGetOffsetList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

// Helper Functions.
const getSourceListApi = ({
  currentUser,
  nameFilter,
  location_id,
  subsidiary_id,
}) => {
  let url = `/rest/supplier-sources?group_id=${currentUser?.group?.id}`;
  if (nameFilter) {
    url = `${url}&name=${nameFilter}`;
  }
  if (subsidiary_id) {
    url = `${url}&subsidiary_id=${subsidiary_id}`;
  }
  if (location_id) {
    url = `${url}&location_id=${location_id}`;
  }
  return url;
};

const getPayloadSources = () => {
  const currentUser = getSession();
  const filter = useSelector((state) => state.common.filter);
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );
  const currentPageFilters = filter?.[currentPageConfig?.currentPage]?.sources;
  const nameFilter = currentPageFilters?.name;
  return {
    currentUser,
    nameFilter,
    ...getSstDropDownData(),
  };
};

// Services.
export const useApiGetSources = (shouldFetch) => {
  const payload = getPayloadSources();
  return useSWR(
    shouldFetch ? () => getSourceListApi(payload) : null,
    async (url) => apiGetSourceList(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};
