import {
    Button, Divider, Grid, Typography
} from "@mui/material";


export default function FormAboutToTimeOut({ onClickLogOut, onStayLoggedIn }) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Your Session’s About to Time Out
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="16px">
            You’ve been inactive for a few minutes. To protect your account,
            we’ll log you out in <b>[LIVE COUNTDOWN]</b> seconds. Do you want to
            extend your session?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={onClickLogOut} fullWidth>
            No, log out
          </Button>
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="center">
          <Button variant="contained" onClick={onStayLoggedIn} fullWidth>
            Yes, stay logged in
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
