import { Button, Grid, Box, Stack } from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";

import MySelect from "components/ui/inputs/MySelect";
import MyRadioGroup from "components/ui/inputs/MyRadioGroup";

export default function Form5SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MyRadioGroup
            row
            list={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            label="Does your company own or lease any vehicles?"
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
