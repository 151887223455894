import { Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as IconNoDataSource } from "../../../../../assets/svg/sources/nodata.svg";

export default function NoData() {
  return (
    <Stack spacing={2} alignItems="center" py={30}>
      <IconNoDataSource height={100} width="auto" />

      <Typography align="center" pt={5} width={"400px"}>
        See the data source for each of your suppliers here!
      </Typography>
    </Stack>
  );
}
