import React from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  InputBase,
  Box as MuiBox,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { htmlDecode } from "utils/common";

const Box = styled(MuiBox)`
  border: 1px solid #8aa3d1;
  border-radius: ${(props) => props.theme.spacing(1)};
  transition: 0.3s;
  padding: 5px 12px 3px 12px;
  box-sizing: border-box;
  position: "relative";
  &: hover {
    transition: 0.3s;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
  &: focus-within {
    transition: 0s;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export default function MyTextField({
  label = undefined,
  helperText = undefined,
  required = true,
  focused = false,
  errorMessage,
  error = false,
  autoComplete = "on",
  sxLabel = "",
  sx = {},
  labelSx = {},
  toolTip = false,
  noteToolTip = undefined,
  ...props
}) {
  const theme = useTheme();

  return (
    <FormControl error={Boolean(errorMessage) || error} variant="standard">
      {label && (
        <InputLabel sx={{ fontSize: "16px", ...labelSx }} shrink={true}>
          {label} {required ? "*" : undefined}
        </InputLabel>
      )}
      <Box sx={sx} mt={label ? theme.spacing(6) : 0}>
        <InputBase
          sx={{ width: "100%" }}
          autoComplete={autoComplete}
          {...props}
          value={htmlDecode(props?.value)}
        />
      </Box>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
