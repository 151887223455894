import styled from "@emotion/styled";
import {
  Box as MuiBox,
  Grid,
  Stack as MuiStack,
  Typography as MuiTypography,
} from "@mui/material";
import ImageLogo from "assets/svg/logo.svg";
import ImageGlobe from "assets/svg/globe.svg";

const Box = styled(MuiBox)((props) => ({
  backgroundColor: props.theme.palette.primary.main,
  width: "100%",
  [props.theme.breakpoints.up("sm")]: {
    height: "100vh",
    padding: props.theme.spacing(8),
  },
  padding: props.theme.spacing(4),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const Stack = styled(MuiStack)((props) => ({
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  [props.theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  width: "100%",
  flexWrap: "nowrap",
}));

const Typography = styled(MuiTypography)((props) => ({
  justifyContent: "center",
  flexDirection: "row",
  textAlign: "center",
  margin: 0,
  [props.theme.breakpoints.up("sm")]: {
    textAlign: "end",
    fontSize: props.active ? "40px" : "21px",
    marginTop: 0,
    marginBottom: 20,
  },
  width: "100%",
  color: "white",
  fontWeight: props.active ? 800 : 200,
  marginTop: 0,
  marginBottom: 0,
}));

export default function StepperSignup({
  activeStep = 1,
  children,
  list,
  onChangeStep,
}) {
  return (
    <Grid container position="relative">
      <Grid item xs={12} sm={4} md={3} position="fixed" width="100%" zIndex={1}>
        <Box>
          <Stack spacing={0}>
            {list.map((item) => {
              return (
                <>
                  {activeStep >= item?.index &&
                  !(activeStep >= 2 && item?.index == 1) ? (
                    <Typography
                      margin={0}
                      active={activeStep == item?.index}
                      component="div"
                      sx={{ cursor: "pointer" }}
                      dangerouslySetInnerHTML={{
                        __html: `${item?.label}`,
                      }}
                      onClick={(e) => onChangeStep(item?.index)}
                    />
                  ) : (
                    <Typography
                      margin={0}
                      component="div"
                      sx={{ cursor: "not-allowed" }}
                      dangerouslySetInnerHTML={{
                        __html: `${item?.label}`,
                      }}
                    />
                  )}
                </>
              );
            })}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={3}></Grid>

      <Grid item xs={12} sm={8} md={9} sx={{ position: "relative" }}>
        <img
          src={ImageGlobe}
          style={{
            position: "absolute",
            zIndex: -1,
            opacity: 0.2,
            width: "100%",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "90vh",
          }}
        />
        <MuiStack
          overflow={"scroll"}
          pb={30}
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          width="100%"
        >
          <MuiBox pt={20} pb={10}>
            <img src={ImageLogo} style={{ maxWidth: 320, width: "100%" }} />
          </MuiBox>
          <MuiStack flexGrow={1} maxWidth={560} justifyContent="center" p={4}>
            {children}
          </MuiStack>
        </MuiStack>
      </Grid>
    </Grid>
  );
}
