import colors from "./colors";
import { green, grey, blueGrey, indigo, red, blue } from "@mui/material/colors";

const typography = {
  fontFamily: [
    "SofiaProLight",
    "SofiaProMedium",
    "SofiaProSemiBold",
    "SofiaPro",
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "60px",
    // fontWeight: 800,
    lineHeight: 1.25,
  },
  h2: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h3: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h5: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontFamily: "SofiaProSemiBold",
    fontSize: "1rem",
    lineHeight: 1.25,
  },
  body1: {
    fontSize: 14,
  },
  body1SemiBold: {
    fontSize: 13,
    color: colors.primary.main,
    fontFamily: "SofiaProSemiBold",
  },
  button: {
    textTransform: "none",
  },
  allVariants: {
    color: blue[900],
  },
  saveButton: {
    textTransform: "none",
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "SofiaProSemiBold",
  },
  editButton: {
    textTransform: "none",
    color: "#93D500",
    fontSize: "14px",
    fontFamily: "SofiaProSemiBold",
  },
  deleteButton: {
    fontSize: "14px",
    fontFamily: "SofiaProSemiBold",
  },
  myTaskSaveButton: {
    fontSize: "13px",
    fontFamily: "SofiaPro",
  },
  myTaskStartButton: {
    fontSize: "13px",
    color: "#FFF",
    fontFamily: "SofiaPro",
  },
  locationSubHeading: {
    fontSize: "14px",
    color: colors.primary.main,
    fontFamily: "SofiaPro",
  },
};

export default typography;
