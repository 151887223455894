import { sign, verify } from "jsonwebtoken";
import jwtDecode from "jwt-decode";

const isValidToken = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//   }, timeLeft);
// };

const getAccessToken = () => {
  const tokenData = window.localStorage.getItem("token_data");
  if (!tokenData) return undefined;
  return JSON.parse(tokenData || {})?.access_token;
};

const getRefreshToken = () => {
  const tokenData = window.localStorage.getItem("token_data");
  if (!tokenData) return undefined;
  return JSON.parse(tokenData || {})?.refresh_token;
};

const setTokenData = (tokenData) => {
  localStorage.setItem("token_data", JSON.stringify(tokenData));
};

const setSession = ({ currentUser }) => {
  if (currentUser) {
    localStorage.setItem("current_user", JSON.stringify(currentUser));

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    clearSession();
  }
};

const getSession = () => {
  try {
    if (!JSON.parse(localStorage.getItem("current_user"))) {
      return {};
    }
    return JSON.parse(localStorage.getItem("current_user"));
  } catch (e) {
    return {};
  }
};

const clearSession = () => {
  localStorage.removeItem("current_user");
  localStorage.removeItem("token_data");
  localStorage.removeItem("async_imports");
};

export {
  verify,
  sign,
  isValidToken,
  getAccessToken,
  setTokenData,
  setSession,
  getSession,
  clearSession,
  getRefreshToken,
};
