import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: {},
  currentPageConfig: {},
  currentUserConfig: {},
  commonApiData: {},
  sort: {},
  sstDropdown: [],
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setCurrentPageConfig: (state, action) => {
      state.currentPageConfig = action.payload;
    },
    setCurrentUserConfig: (state, action) => {
      state.currentUserConfig = action.payload;
    },
    setCommonApiData: (state, action) => {
      state.commonApiData = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSstDropdown: (state, action) => {
      state.sstDropdown = action.payload;
    },
  },
});

export const {
  setFilter,
  setCurrentPageConfig,
  setCurrentUserConfig,
  setCommonApiData,
  setSort,
  setSstDropdown,
} = common.actions;

export default common.reducer;
