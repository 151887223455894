import { Box, Stack } from "@mui/material";
import React from "react";
import FormOtp from "../../../components/auth/resetPassword/forms/FormOtp";
import StyledBackground from "../../../components/common/backgrounds/StyledBackground";

import FormCreatePassword from "components/auth/resetPassword/forms/FormCreatePassword";
import FormPasswordUpdated from "components/auth/resetPassword/forms/FormPasswordUpdated";
import useAuth from "hooks/useAuth";
import useSnackbar from "hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../assets/svg/logo.svg";
import useBackdropProgress from "hooks/useBackdropProgress";

export default function OtpResetPassword() {
  let { timestamp, account_id, token } = useParams();
  const { toggleBackdropProgress } = useBackdropProgress();

  const [form, setForm] = React.useState(undefined);
  const [currentUser, setCurrentUser] = React.useState(undefined);
  const { resetPasswordStep3, resetPasswordStep2, sendSMSVerfication } =
    useAuth();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();

  React.useEffect(() => {
    const verify = async () => {
      try {
        const data = await resetPasswordStep2({ timestamp, account_id, token });
        setCurrentUser(data.current_user);
        setForm(0);
      } catch (error) {
        const message = error.message || "Something went wrong";
        showSnackbar(message, "error");
      }
    };

    verify();
  }, []);

  const handleLogin = (e) => {
    navigate("/auth/login");
  };

  const handleFormOneSubmit = async ({ otp }) => {
    try {
      toggleBackdropProgress(true);
      const smsVerificationResponse = await sendSMSVerfication({ otp, id: currentUser.uid });
      if (!smsVerificationResponse?.status) {
        showSnackbar("Please enter correct OTP.", "error");
        toggleBackdropProgress(false);
        return;
      }
      toggleBackdropProgress(false);
      showSnackbar("OTP verfied", "success");
      setForm(1);
    } catch (error) {
      toggleBackdropProgress(true);
      const message = error.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleFormTwoSubmit = async ({ password }) => {
    try {
      toggleBackdropProgress(true);
      await resetPasswordStep3({ password, id: currentUser.uuid });
      toggleBackdropProgress(false);
      setForm(2);
    } catch (error) {
      toggleBackdropProgress(true);
      const message = error.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const handleFormThreeSubmit = async ({ password }) => {
    navigate("/auth/login");
  };

  const getForm = () => {
    if (form == 2) {
      return <FormPasswordUpdated onSubmit={handleFormThreeSubmit} />;
    } else if (form === 1) {
      return (
        <FormCreatePassword
          onSubmit={handleFormTwoSubmit}
          onLogin={handleLogin}
        />
      );
    } else if (form === 0) {
      return (
        <FormOtp
          onSubmit={handleFormOneSubmit}
          data={{ mobileNumber: currentUser?.mobile_number }}
        />
      );
    } else return null;
  };

  return (
    <div>
      <StyledBackground bgcolor="#C1D9EE">
        <Stack spacing={10}>
          <img src={Logo} />
          <Box sx={{ width: "100%", maxWidth: 360 }}>{getForm()}</Box>
        </Stack>
      </StyledBackground>
    </div>
  );
}
