import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import { useSelector } from "react-redux";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  position: relative;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen((o) => !o);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar
        variant="temporary"
        PaperProps={{ style: { width: drawerWidth } }}
        items={dashboardItems}
        open={open}
        selectedItem={currentPageConfig?.currentPage}
        onClose={handleDrawerToggle}
      />
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent px={isLgUp ? 8 : 5} py={isLgUp ? 3 : 5} sx={{ zIndex: 1 }}>
          <Outlet />
        </MainContent>
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default Dashboard;
