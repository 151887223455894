import { Button, Divider, Grid, Typography } from "@mui/material";

export default function FormTimedOut({ onClickLogIn }) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Your Session Timed Out
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="16px">
            You were inactive for five minutes. To protect your account, we’ve
            logged you out.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={onClickLogIn}>
            Log In
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
