import { CacheProvider } from "@emotion/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import routes from "./routes";
import createTheme from "./theme";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { CssBaseline } from "@mui/material";
import BackdropProgressProvider from "contexts/ContextBackdropProgress";
import { AsyncFileImportProvider } from "contexts/ContextAsyncFileImport";
import { SnackbarProvider } from "contexts/ContextSnackbar";
import { SWRConfig } from "swr";
import { setSession } from "utils/jwt";
import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const navigate = useNavigate();

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Axis AI - Carbonpay"
          defaultTitle="Axis AI - Carbonpay"
        />
        <SWRConfig
          value={{
            onError: (error, key) => {
              const status = error?.response?.status;
              if (status === 401) {
                setSession(null, null);
                navigate("/auth/login");
              }
            },
          }}
        >
          <SnackbarProvider>
            <BackdropProgressProvider>
              <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MuiThemeProvider theme={createTheme(theme)}>
                    <CssBaseline />
                    <AuthProvider>
                      <AsyncFileImportProvider>
                        {content}
                      </AsyncFileImportProvider>
                    </AuthProvider>
                  </MuiThemeProvider>
                </LocalizationProvider>
              </Provider>
            </BackdropProgressProvider>
          </SnackbarProvider>
        </SWRConfig>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
