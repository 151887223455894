import { Grid, Typography as MuiTypography, Button } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const Title = styled(MuiTypography)`
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 112%;
  text-align: center;
  margin-bottom: 2rem;
`;

const Description = styled(MuiTypography)`
  font-family: SofiaProLight;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 128%;
`;

export default function Form1SignUpStep1({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Assessing your Axis Checklist:</Title>
        </Grid>
        <Grid item xs={12}>
          <Description>To get a scope on your scopes please have:</Description>
          <ul>
            <li>
              <Description>Country of company registration</Description>
            </li>
            <li>
              <Description>List of all subsidiaries</Description>
            </li>
            <li>
              <Description>List of all associated locations</Description>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            style={{
              border: "2px solid",
              fontWeight: "bold",
              marginTop: "1.4rem",
            }}
            onClick={handleSubmit}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
