import CompSignUpStep2 from "components/auth/signUp/Step2";
import StepperSignup from "layouts/auth/signUp/stepper/StepperSignup";
import React from "react";

const list = [
  "Getting Started",
  "Full Time Employees",
  "Location",
  "Subsidiaries",
  "Summary",
];

export default function SignUpStep2() {
  const [step, setStep] = React.useState(0);

  const handleChangeForm = (form) => {
    if (form === 1) {
      setStep(0);
    } else if (form === 2 || form === 3) {
      setStep(1);
    } else if (form === 4) {
      setStep(2);
    } else if (form >= 5 && form <= 11) {
      setStep(3);
    } else {
      setStep(4);
    }
  };

  return (
    <div>
      <StepperSignup activeStep={step} list={list}>
        <CompSignUpStep2 onChangeForm={handleChangeForm} />
      </StepperSignup>
    </div>
  );
}
