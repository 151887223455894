import React, { forwardRef, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { Box, List, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, useLocation } from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "../../vendor/perfect-scrollbar.css";
import { setCurrentPageConfig } from "redux/slices/common";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "hooks/useAuth";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const baseScrollbar = (props) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
  height: calc(100% - 64px);
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const TypographySelected = styled(Typography)`
  color: white;
  font-weight: bold;
`;

const TypographyUnselected = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
`;

const SidebarNav = ({ items, selectedItem }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );
  const { logOut } = useAuth();

  const location = useLocation();
  const dispatch = useDispatch();

  const divRef = useRef(null);
  let scrollTimeout;

  const handleWheel = (event) => {
    document.body.classList.add("no-scroll"); // Add class to disable scrolling on body

    // Clear the previous timeout if exists
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    // Set a new timeout to remove the class after a short delay (e.g., 300ms)
    scrollTimeout = setTimeout(() => {
      document.body.classList.remove("no-scroll"); // Remove class to re-enable scrolling on body
    }, 300);

    // Calculate the new scroll position
    const div = divRef.current;
    const delta = event.deltaY;
    div.scrollTop += delta;
  };

  React.useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].href === location.pathname ||
        items[i].href.includes("settings")
      ) {
        dispatch(
          setCurrentPageConfig({
            ...currentPageConfig,
            currentPage: items[i].uniqueKey,
          })
        );
        break;
      }
    }
  }, []);

  return (
    <ScrollbarComponent ref={divRef} onWheel={handleWheel}>
      <List style={{ overflowX: "hidden", padding: 0 }}>
        {items.map(({ title, IconPrimary, IconWhite, href, uniqueKey }) => (
          <ListItem key={title} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 60,
                justifyContent: open ? "initial" : "center",
                px: 4,
              }}
              onClick={(e) => {
                if (uniqueKey === "logout") {
                  logOut();
                  return;
                }
                dispatch(
                  setCurrentPageConfig({
                    ...currentPageConfig,
                    currentPage: uniqueKey,
                  })
                );
              }}
              selected={uniqueKey === selectedItem}
              component={CustomRouterLink}
              to={href}
            >
              <ListItemIcon
                sx={{
                  width: 0,
                  mr: open ? 5 : "auto",
                  justifyContent: "center",
                }}
              >
                {uniqueKey !== selectedItem ? <IconPrimary /> : <IconWhite />}
              </ListItemIcon>

              <ListItemText
                primary={
                  uniqueKey === selectedItem ? (
                    <TypographySelected
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  ) : (
                    <TypographyUnselected
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
