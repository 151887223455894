import { withTheme } from "@emotion/react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function MySelect({
  theme,
  defaultValue,
  menuList,
  errorMessage,
  required = true,
  label,
  labelSx = {},
  ...props
}) {
  return (
    <FormControl size="small" error={Boolean(errorMessage)}>
      {label && (
        <FormLabel sx={{ ...labelSx }}>
          {label} {required ? "*" : undefined}
        </FormLabel>
      )}
      <Box mt={label ? theme.spacing(2) : 0} width="100%">
        <Select
          IconComponent={KeyboardArrowDownIcon}
          value={defaultValue || menuList?.[0]?.value}
          fullWidth
          {...props}
          sx={{
            ...props?.sx,
            "& > .MuiSelect-select": {
              "& > .MuiTypography-root": {
                "> img": {
                  display: "none",
                },
              },
            },
          }}
        >
          {menuList.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                color: item.color,
                fontWeight: item.isHighlighted ? "bold" : 500,
                display: item.hide ? "none" : "flex",
              }}
              disabled={item.isDisabled}
            >
              {item?.icon && (
                <Typography variant="span" sx={{ mr: 2, display: "flex" }}>
                  <img src={item?.icon} height={20} width={20} />
                </Typography>
              )}
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {errorMessage && (
        <FormHelperText sx={{ margin: "4px 0 0 0" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

MySelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
};

export default withTheme(MySelect);
