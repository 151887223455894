import { useApiGetSources } from "hooks/api/settings";
import Source from "layouts/settings/sources/Source";
import useAuth from "hooks/useAuth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setFilter } from "redux/slices/common";
import { cloneDeep, set } from "lodash";
import { areAllValuesNull } from "utils/common";
import { getSstDropDownData } from "hooks/api/common";

export default function Sources() {
  const { isMounted } = useAuth();
  const { data, error, isLoading } = useApiGetSources(isMounted);

  if (!isMounted) return null;

  const dispatch = useDispatch();

  const filter = useSelector((state) => state.common.filter);
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );
  const currentPageFilters = filter?.[currentPageConfig?.currentPage]?.sources;
  const sstDropdownFilters = getSstDropDownData();

  const handleSearch = (e) => {
    // REDUX
    const filterClone = cloneDeep(filter);
    dispatch(
      setFilter(
        set(
          filterClone,
          `${currentPageConfig?.currentPage}.sources.name`,
          e?.target?.value
        )
      )
    );
  };

  return (
    <Source
      rowList={data}
      error={error}
      isLoading={isLoading}
      handleSearch={handleSearch}
      isFilterApplied={areAllValuesNull({
        ...currentPageFilters,
        ...sstDropdownFilters,
      })}
    />
  );
}
