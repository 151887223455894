import { ReactComponent as IconPrimaryAxis } from "../../assets/svg/axis/primary.svg";
import { ReactComponent as IconWhiteAxis } from "../../assets/svg/axis/white.svg";

import { ReactComponent as IconPrimaryAnalytics } from "../../assets/svg/analytics/primary.svg";
import { ReactComponent as IconWhiteAnalytics } from "../../assets/svg/analytics/white.svg";

import { ReactComponent as IconPrimaryCategory } from "../../assets/svg/categories/primary.svg";
import { ReactComponent as IconWhiteCategory } from "../../assets/svg/categories/white.svg";

import { ReactComponent as IconPrimaryTransactions } from "../../assets/svg/transactions/primary.svg";
import { ReactComponent as IconWhiteTransactions } from "../../assets/svg/transactions/white.svg";

import { ReactComponent as IconPrimarySuppliers } from "../../assets/svg/suppliers/primary.svg";
import { ReactComponent as IconWhiteSuppliers } from "../../assets/svg/suppliers/white.svg";

import { ReactComponent as IconPrimaryBenchmarking } from "../../assets/svg/benchmarking/primary.svg";
import { ReactComponent as IconWhiteBenchmarking } from "../../assets/svg/benchmarking/white.svg";

import { ReactComponent as IconPrimaryReports } from "../../assets/svg/reports/primary.svg";
import { ReactComponent as IconWhiteReports } from "../../assets/svg/reports/white.svg";

import { ReactComponent as IconPrimaryCatalog } from "../../assets/svg/catalog/primary.svg";
import { ReactComponent as IconWhiteCatalog } from "../../assets/svg/catalog/white.svg";

import { ReactComponent as IconPrimaryFleet } from "../../assets/svg/fleet/primary.svg";
import { ReactComponent as IconWhiteFleet } from "../../assets/svg/fleet/white.svg";

import { ReactComponent as IconPrimaryLocation } from "../../assets/svg/location/primary.svg";
import { ReactComponent as IconWhiteLocation } from "../../assets/svg/location/white.svg";

import { ReactComponent as IconPrimarySettings } from "../../assets/svg/settings/primary.svg";
import { ReactComponent as IconWhiteSettings } from "../../assets/svg/settings/white.svg";

import { ReactComponent as IconPrimaryNotifications } from "../../assets/svg/notifications/primary.svg";
import { ReactComponent as IconWhiteNotifications } from "../../assets/svg/notifications/white.svg";

import { ReactComponent as IconPrimaryLogout } from "../../assets/svg/logout/primary.svg";

import { ReactComponent as IconPrimaryAInquire } from "../../assets/svg/ainquire/primary.svg";
import { ReactComponent as IconWhiteAInquire } from "../../assets/svg/ainquire/white.svg";

const pagesSection = [
  {
    href: "/axis",
    IconPrimary: IconPrimaryAxis,
    IconWhite: IconWhiteAxis,
    title: "Axis",
    uniqueKey: "axis",
  },
  {
    href: "/analytics",
    IconPrimary: IconPrimaryAnalytics,
    IconWhite: IconWhiteAnalytics,
    title: "Analytics",
    uniqueKey: "analytics",
  },
  {
    href: "/categories",
    IconPrimary: IconPrimaryCategory,
    IconWhite: IconWhiteCategory,
    title: "Categories",
    uniqueKey: "categories",
  },
  {
    href: "/transactions",
    IconPrimary: IconPrimaryTransactions,
    IconWhite: IconWhiteTransactions,
    title: "Transactions",
    uniqueKey: "transactions",
  },
  {
    href: "/suppliers",
    IconPrimary: IconPrimarySuppliers,
    IconWhite: IconWhiteSuppliers,
    title: "Suppliers",
    uniqueKey: "suppliers",
  },
  {
    href: "/benchmarking",
    IconPrimary: IconPrimaryBenchmarking,
    IconWhite: IconWhiteBenchmarking,
    title: "Benchmarking",
    uniqueKey: "benchmarking",
  },
  {
    href: "/reports",
    IconPrimary: IconPrimaryReports,
    IconWhite: IconWhiteReports,
    title: "Reports",
    uniqueKey: "reports",
  },
  // {
  //   href: "/catalog",
  //   IconPrimary: IconPrimaryCatalog,
  //   IconWhite: IconWhiteCatalog,
  //   title: "Catalog",
  //   uniqueKey: "catalog",
  // },
  {
    href: "/fleet",
    IconPrimary: IconPrimaryFleet,
    IconWhite: IconWhiteFleet,
    title: "Fleet",
    uniqueKey: "fleet",
  },
  {
    href: "/locations",
    IconPrimary: IconPrimaryLocation,
    IconWhite: IconWhiteLocation,
    title: "Locations",
    uniqueKey: "locations",
  },
  {
    href: "/settings/profile",
    IconPrimary: IconPrimarySettings,
    IconWhite: IconWhiteSettings,
    title: "Settings",
    uniqueKey: "settings",
  },
  {
    href: "/my-tasks",
    IconPrimary: IconPrimaryNotifications,
    IconWhite: IconWhiteNotifications,
    title: "My Tasks",
    uniqueKey: "my-tasks",
  },
  {
    href: "/a-inquire",
    IconPrimary: IconPrimaryAInquire,
    IconWhite: IconWhiteAInquire,
    title: "A Inquire",
    uniqueKey: "a-inquire",
  },
  {
    href: "#",
    IconPrimary: IconPrimaryLogout,
    title: "Logout",
    uniqueKey: "logout",
  },
];

const navItems = pagesSection;

export default navItems;
