import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";
import React from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { validateEmail, validatePassword } from "utils/validation";
import MyPhoneSelect from "components/ui/inputs/MyPhoneSelect";

export default function Form2SignUpStep1({ onSubmit, defaultValues }) {
  const [firstName, setFirstName] = React.useState(
    defaultValues?.first_name || ""
  );
  const [lastName, setLastName] = React.useState(
    defaultValues?.last_name || ""
  );
  const [email, setEmail] = React.useState(defaultValues?.mail || "");
  const [mobileNumber, setMobileNumber] = React.useState(
    defaultValues?.mobile_number || ""
  );
  const [password, setPassword] = React.useState(defaultValues?.password || "");
  const [confirmPassword, setConfirmPassword] = React.useState(
    defaultValues?.password || ""
  );

  const [errorFirstName, setErrorFirstName] = React.useState(undefined);
  const [errorLastName, setErrorLastName] = React.useState(undefined);
  const [errorEmail, setErrorEmail] = React.useState(undefined);
  const [errorMobileNumber, setErrorMobileNumber] = React.useState(undefined);
  const [errorPassword, setErrorPassword] = React.useState(undefined);
  const [errorConfirmPassword, setErrorConfirmPassword] =
    React.useState(undefined);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    let flag = false;
    if (firstName.length === 0) {
      setErrorFirstName("First name is required");
      flag = true;
    }
    if (lastName.length === 0) {
      setErrorLastName("Last name is required");
      flag = true;
    }

    if (!mobileNumber || mobileNumber.length === 0) {
      setErrorMobileNumber("Mobile number is required");
      flag = true;
    }

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      setErrorEmail(emailValidation);
      flag = true;
    }

    const passwordValidation = validatePassword(password);
    if (passwordValidation) {
      setErrorPassword(passwordValidation);
      flag = true;
    }

    if (password != confirmPassword) {
      setErrorConfirmPassword("Passwords do not match");
      flag = true;
    }

    if (flag) return;

    onSubmit({
      firstName,
      lastName,
      email,
      mobileNumber,
      password,
    });
  };

  const handleChangeFirstName = (e) => {
    setErrorFirstName(undefined);
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
    setErrorLastName(undefined);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorEmail(validateEmail(e.target.value));
  };

  const handleChangeMobileNumber = (e) => {
    setMobileNumber(e?.validData?.phoneNumber);
    setErrorMobileNumber(e?.message);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorPassword(validatePassword(e.target.value));
    confirmPassword &&
      setErrorConfirmPassword(e.target.value !== confirmPassword ? "" : null);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword(password !== e.target.value ? "" : null);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl
            error={errorFirstName || errorLastName}
            variant="standard"
          >
            <InputLabel shrink sx={{ fontSize: "16px" }}>
              Please enter your full name:*
            </InputLabel>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "0 !important" }}>
          <MyTextField
            value={firstName}
            onChange={handleChangeFirstName}
            errorMessage={errorFirstName}
            placeholder={"First name"}
          />
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "0 !important" }}>
          <MyTextField
            value={lastName}
            onChange={handleChangeLastName}
            errorMessage={errorLastName}
            placeholder={"Last name"}
          />
        </Grid>
        <Grid item xs={12}>
          <MyTextField
            value={email}
            label="Please enter your email address:"
            onChange={handleChangeEmail}
            errorMessage={errorEmail}
            placeholder={"Enter your email address"}
          />
        </Grid>
        <Grid item xs={12}>
          <MyPhoneSelect
            label="Please enter your mobile number:"
            placeholder={"Enter your mobile number"}
            onChange={handleChangeMobileNumber}
            errorMessage={errorMobileNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <MyTextField
            value={password}
            onChange={handleChangePassword}
            type={showPassword ? "text" : "password"}
            label="Please create a password:"
            errorMessage={errorPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={"Enter password"}
          />
        </Grid>
        <Grid item xs={12}>
          <MyTextField
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            label="Please confirm a password:"
            errorMessage={errorConfirmPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={"Retype password"}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{
              mt: 4,
              width: "50%",
            }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
