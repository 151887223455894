import { Button, Grid, Box, Stack } from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";

import MySelect from "components/ui/inputs/MySelect";
import MyRadioGroup from "components/ui/inputs/MyRadioGroup";
import MyCheckbox from "components/ui/inputs/MyCheckbox";

export default function Form6SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MyCheckbox
            row
            list={[
              { value: "1", label: "Company Name" },
              { value: "2", label: "Company Name" },
              { value: "3", label: "Company Name" },
              { value: "4", label: "Company Name" },
              { value: "5", label: "Company Name" },
            ]}
            label="Please mark all the subsidiaries that have fleets:"
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
