import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const Title = styled(MuiTypography)`
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 112%;
  text-align: center;
  margin-bottom: 1rem;
`;

export default function Form13SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Title>Success!</Title>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <MuiTypography fontWeight="bold" textAlign="center" maxWidth={400}>
            Thank you for answering our questions! You've finished answering our
            Onboarding Questionnaire. One of our Customer Success
            Representatives will send you an email with the final onboarding
            steps.
          </MuiTypography>
        </Grid>
      </Grid>
    </div>
  );
}
