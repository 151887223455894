import { Button, Grid, Stack, Typography } from "@mui/material";
import InputUploadFile from "components/ui/inputs/UploadFile";
import React from "react";

export default function FormImportDataFilesSignUpStep3({ onClickBack, onClickNext }) {
  const [file, setFile] = React.useState(null);

  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClickBack = (e) => {
    onClickBack();
  };

  const handleClickNext = (e) => {
    onClickNext({ file });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align="center">Upload your data files.</Typography>
      </Grid>

      <Grid item xs={12}>
        <InputUploadFile
          accept=".csv"
          placeholder="Upload a File"
          onChange={handleChangeFile}
        />
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="contained" onClick={handleClickNext}>
            Next
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
