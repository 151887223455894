import { Box, Stack } from "@mui/material";
import StyledBackground from "../../../components/common/backgrounds/StyledBackground";

import FormTimedOut from "components/session/timedOut/forms/FormTimedOut";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/svg/logo.svg";

export default function TimedOut() {
  const navigate = useNavigate();

  const handleLogIn = (e) => {
    navigate("/auth/login");
  };

  const getForm = () => {
    return <FormTimedOut onClickLogIn={handleLogIn} />;
  };
  return (
    <div>
      <StyledBackground bgcolor="#255097">
        <Stack spacing={10}>
          <img src={Logo} />
          <Box sx={{ width: "100%", maxWidth: 360 }}>{getForm()}</Box>
        </Stack>
      </StyledBackground>
    </div>
  );
}
