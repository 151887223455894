import { Box, Stack } from "@mui/material";
import StyledBackground from "../../../components/common/backgrounds/StyledBackground";

import FormEnterEmail from "components/auth/resetPassword/forms/FormEnterEmail";
import useAuth from "hooks/useAuth";
import useBackdropProgress from "hooks/useBackdropProgress";
import useSnackbar from "hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/svg/logo.svg";

export default function ResetPassword() {

  const { resetPasswordStep1 } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { toggleBackdropProgress } = useBackdropProgress();

  const navigate = useNavigate();

  const handleLogin = (e) => {
    navigate("/auth/login");
  };

  const handleFormOneSubmit = async ({ email }) => {
    try {
      toggleBackdropProgress(true);
      await resetPasswordStep1({ email });
      toggleBackdropProgress(false);
      showSnackbar("We have sent you an email on your inbox");
    } catch (error) {
      toggleBackdropProgress(false);
      const message = error.message || "Something went wrong";
      showSnackbar(message, "error");
    }
  };

  const getForm = () => {
    return <FormEnterEmail onSubmit={handleFormOneSubmit} onLogin={handleLogin} />;
  };

  return (
    <div>
      <StyledBackground bgcolor="#C1D9EE">
        <Stack spacing={10}>
          <img src={Logo} />
          <Box sx={{ width: "100%", maxWidth: 360 }}>{getForm()}</Box>
        </Stack>
      </StyledBackground>
    </div>
  );
}
