import { Alert, Snackbar } from "@mui/material";
import React, { createContext } from "react";

const ContextSnackbar = createContext(null);

function SnackbarProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");

  const handleClose = (e) => {
    setOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  return (
    <ContextSnackbar.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </ContextSnackbar.Provider>
  );
}

export { ContextSnackbar, SnackbarProvider };
