import { withTheme } from "@emotion/react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { PhoneInput } from "react-contact-number-input";


function MyPhoneSelect({ theme, errorMessage, required = true, label, ...props }) {
  return (
    <FormControl size="small" error={errorMessage && true}>
      {label && (
        <FormLabel>
          {label} {required ? "*" : undefined}
        </FormLabel>
      )}
      <Box mt={label ? theme.spacing(2) : 0} width="100%">
        <PhoneInput {...props} />
      </Box>
      {errorMessage && (
        <FormHelperText sx={{ margin: "4px 0 0 0" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

MyPhoneSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

export default withTheme(MyPhoneSelect);
