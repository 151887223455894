import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import OtpResetPassword from "pages/auth/OtpResetPassword";

//Session timeout
import AboutToTimeOut from "pages/session/AboutToTimeout";
import Timedout from "pages/session/Timedout";

import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import SignUpStep1 from "pages/auth/signUp/Step1";
import SignUpStep2 from "pages/auth/signUp/Step2";
import SignUpStep3 from "pages/auth/signUp/Step3";
import Sources from "pages/Settings/Sources";

const Axis = async(() => import("./pages/Axis"));
const Transactions = async(() => import("./pages/Transactions"));
const Analytics = async(() => import("./pages/Analytics"));
const Suppliers = async(() => import("./pages/Suppliers"));
const Benchmarking = async(() => import("./pages/Benchmarking"));
const Reports = async(() => import("./pages/Reports"));
const Catalog = async(() => import("./pages/Catalog"));
const Category = async(() => import("./pages/Category"));
const Fleet = async(() => import("./pages/Fleet"));
const Locations = async(() => import("./pages/Locations"));
const Settings = async(() => import("./pages/Settings"));
const Profile = async(() => import("./pages/Settings/Profile"));
const Company = async(() => import("./pages/Settings/Company"));
const Department = async(() => import("./pages/Settings/Department"));
const Imports = async(() => import("./pages/Settings/Imports"));
const Projects = async(() => import("./pages/Settings/Projects"));
const Targets = async(() => import("./pages/Settings/Targets"));
const Team = async(() => import("./pages/Settings/Team"));
const Records = async(() => import("./pages/Settings/Records"));
const Offsets = async(() => import("./pages/Settings/Offsets"));
const Help = async(() => import("./pages/Settings/Help"));
const MyTasks = async(() => import("./pages/MyTasks"));
const AInquire = async(() => import("./pages/AInquire"));
const Metrics = async(() => import("./pages/Settings/Metrics"));

const routes = [
  {
    path: "session",
    children: [
      {
        path: "about-to-time-out",
        element: <AboutToTimeOut />,
      },
      {
        path: "timed-out",
        element: <Timedout />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "settings",
        element: <Settings />,
        children: [
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "company",
            element: <Company />,
          },
          {
            path: "team",
            element: <Team />,
          },
          {
            path: "department",
            element: <Department />,
          },
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: "targets",
            element: <Targets />,
          },
          {
            path: "imports",
            element: <Imports />,
          },
          {
            path: "records",
            element: <Records />,
          },
          {
            path: "mitigation",
            element: <Offsets />,
          },
          {
            path: "sources",
            element: <Sources />,
          },
          {
            path: "help",
            element: <Help />,
          },
          {
            path: "metrics",
            element: <Metrics />,
          },
        ],
      },
    ],
  },
  {
    path: "/axis/:timestamp/:account_id/:token",
    element: <OtpResetPassword />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "axis",
        element: <Axis />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "categories",
        element: <Category />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "suppliers",
        element: <Suppliers />,
      },
      {
        path: "benchmarking",
        element: <Benchmarking />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "fleet",
        element: <Fleet />,
      },
      {
        path: "locations",
        element: <Locations />,
      },
      {
        path: "my-tasks",
        element: <MyTasks />,
      },
      {
        path: "a-inquire",
        element: <AInquire />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "auth",
    children: [
      {
        path: "sign-up",
        children: [
          {
            path: "step-1",
            element: <SignUpStep1 />,
          },
          {
            path: "step-2",
            element: <SignUpStep2 />,
          },
          {
            path: "step-3",
            element: <SignUpStep3 />,
          },
          {
            path: "",
            element: <Page404 />,
          },
          {
            path: "*",
            element: <Page404 />,
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "",
        element: <Page404 />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
