import {
  Button,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  Typography,
  Divider,
  TextField as MuiTextField,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import MyTextField from "components/ui/inputs/MyTextField";
import { validatePassword } from "utils/validation";

const TextField = styled(MuiTextField)`
  // padding: 20px;
`;

export default function FormCreatePassword({ onSubmit, onLogin }) {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [errorPassword, setErrorPassword] = React.useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState("");

  const handleSubmit = (e) => {
    let flag = false;

    const passwordValidation = validatePassword(password);
    if (passwordValidation) {
      setErrorPassword(passwordValidation);
      flag = true;
    }

    if (password != confirmPassword) {
      setErrorConfirmPassword("Passwords do not match");
      flag = true;
    }

    if (flag) return;

    onSubmit({ password });
  };

  const handleLogin = (e) => {
    onLogin(e);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorPassword(validatePassword(e.target.value));
    setErrorConfirmPassword(
      e.target.value !== confirmPassword ? "Passwords do not match" : null
    );
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword(
      password !== e.target.value ? "Passwords do not match" : null
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Choose a New Password
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="16px">
            Your password must have at least:
            <br />
            -8 characters
            <br />
            -one uppercase letter
            <br />
            -one lowercase letter
            <br />
            -one special character (eg !@£$%^&amp;*)
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyTextField
                type="password"
                value={password}
                placeholder="New Password"
                onChange={handleChangePassword}
                errorMessage={errorPassword}
              />
            </Grid>

            <Grid item xs={12}>
              <MyTextField
                type="password"
                value={confirmPassword}
                placeholder="Confirm New Password"
                onChange={handleChangeConfirmPassword}
                errorMessage={errorConfirmPassword}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleSubmit}>
            Change password
          </Button>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Link onClick={handleLogin}>Return to login page</Link>
        </Grid>
      </Grid>
    </div>
  );
}
