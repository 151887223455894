import { Button, Grid, Box, Stack, Typography, FormLabel } from "@mui/material";
import MyTextField from "components/ui/inputs/MyTextField";

import MySelect from "components/ui/inputs/MySelect";
import MyRadioGroup from "components/ui/inputs/MyRadioGroup";

export default function Form8SignUpStep2({ onSubmit }) {
  const handleSubmit = (e) => {
    onSubmit();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
          Please list the fuel card provider for each subsidiary:

          </Typography>
        </Grid>

        <Grid item xs={5}></Grid>

        <Grid item xs={7}>
          <FormLabel>Fuel Card Provider:</FormLabel>
        </Grid>

        <Grid item xs={5} display="flex" alignItems="center">
          <FormLabel>Company Name</FormLabel>
        </Grid>

        <Grid item xs={7}>
          <MyTextField placeholder="Fuel Card Provider"/>
        </Grid>

        <Grid item xs={5} display="flex" alignItems="center">
          <FormLabel>Company Name</FormLabel>
        </Grid>

        <Grid item xs={7}>
          <MyTextField placeholder="Fuel Card Provider"/>
        </Grid>

        <Grid item xs={5} display="flex" alignItems="center">
          <FormLabel>Company Name</FormLabel>
        </Grid>

        <Grid item xs={7}>
          <MyTextField placeholder="Fuel Card Provider"/>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mt: 4, width: "50%" }}
            style={{
              border: "2px solid",
              fontWeight: "bold",
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
