import CompSignUpStep3 from "components/auth/signUp/Step3";
import StepperSignup from "layouts/auth/signUp/stepper/StepperSignup";
import React from "react";

const list = ["Getting Started", "Summary"];

export default function SignUpStep3() {
  const [step, setStep] = React.useState(0);

  const handleChangeForm = (form) => {
    if (form === 1) {
      setStep(0);
    } else {
      setStep(1);
    }
  };

  return (
    <div>
      <StepperSignup activeStep={step} list={list}>
        <CompSignUpStep3 onChangeForm={handleChangeForm} />
      </StepperSignup>
    </div>
  );
}
