import { Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as NoDataIcon } from "../../../../../assets/svg/transactions/nosearchresults.svg";

export default function NoSearchResults() {
  return (
    <Stack spacing={2} alignItems="center" py={30}>
      <NoDataIcon height={70} width="auto" />

      <Typography align="center" pt={5} maxWidth={240}>
        Your search returned no results. Please try different criteria.
      </Typography>
    </Stack>
  );
}
