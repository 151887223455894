import { green, grey, blueGrey, indigo, red, blue } from "@mui/material/colors";

const colors = {
    primary: {
        main: blue[900],
    },
    border: {
        main: "#8aa3d1",
    },
    background: {
        hover: "#f5f8fb",
    },
}



export default colors;