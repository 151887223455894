import { getAccessToken, getSession } from "utils/jwt";
import ajax from "./ajax";

export const apiGetRecordList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiCreateRecord = (data) => {
  const currentUser = getSession();

  return ajax({
    method: "post",
    url: `/jsonapi/axis_record`,
    data: {
      data: {
        type: "axis_record--axis_record",
        attributes: {
          record_year: data?.record_year,
          record_scope1: data?.record_scope1,
          record_scope2: data?.record_scope2,
          record_scope3: data?.record_scope3,
          record_total: data?.record_total,
          benchmark_year: data?.benchmark_year,
        },
        relationships: {
          author: {
            data: {
              type: "user--user",
              id: currentUser?.uuid,
            },
          },
          record_group: {
            data: {
              type: "axis_group--axis_group",
              id: currentUser?.group?.uuid,
            },
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteRecord = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_record/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiUpdateRecords = (data) => {
  return ajax({
    method: "post",
    url: `/rest/entity/record/update`,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetTargetList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiCreateTarget = (data) => {
  const currentUser = getSession();

  return ajax({
    method: "post",
    url: `/jsonapi/axis_target`,
    data: {
      data: {
        type: "axis_target--axis_target",
        attributes: {
          target_year: data?.target_year,
          target_scope1: data?.target_scope1,
          target_scope2: data?.target_scope2,
          target_scope3: data?.target_scope3,
          target_total: data?.target_total,
          target_duration: data?.target_duration,
        },
        relationships: {
          author: {
            data: {
              type: "user--user",
              id: currentUser?.uuid,
            },
          },
          target_group: {
            data: {
              type: "axis_group--axis_group",
              id: currentUser?.group?.uuid,
            },
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteTarget = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_target/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiUpdateTargets = (data) => {
  return ajax({
    method: "post",
    url: `/rest/entity/target/update`,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetProjectList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiCreateProject = (data) => {
  const currentUser = getSession();

  return ajax({
    method: "post",
    url: `/jsonapi/axis_project`,
    data: {
      data: {
        type: "axis_project--axis_project",
        attributes: {
          title: data?.title,
        },
        relationships: {
          author: {
            data: {
              type: "user--user",
              id: currentUser?.uuid,
            },
          },
          project_group: {
            data: {
              type: "axis_group--axis_group",
              id: currentUser?.group?.uuid,
            },
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteProject = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_project/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiUpdateProjects = (data) => {
  return ajax({
    method: "post",
    url: `/rest/entity/project/update`,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetDepartmentList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiCreateDepartment = (data) => {
  const currentUser = getSession();

  return ajax({
    method: "post",
    url: `/jsonapi/axis_department`,
    data: {
      data: {
        type: "axis_department--axis_department",
        attributes: {
          title: data?.title,
        },
        relationships: {
          author: {
            data: {
              type: "user--user",
              id: currentUser?.uuid,
            },
          },
          department_group: {
            data: {
              type: "axis_group--axis_group",
              id: currentUser?.group?.uuid,
            },
          },
          // hod: {
          //   data: [
          //     {
          //       type: "user--user",
          //       id: currentUser?.uuid,
          //     },
          //     {
          //       type: "user--user",
          //       id: currentUser?.uuid,
          //     },
          //   ],
          // },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteDepartment = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_department/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiUpdateDepartments = (data) => {
  return ajax({
    method: "post",
    url: `/rest/entity/department/update`,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetOffsetList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiGetSourceList = (url) => {
  return ajax({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiCreateOffset = (data) => {
  const currentUser = getSession();

  return ajax({
    method: "post",
    url: `/jsonapi/axis_offset`,
    data: {
      data: {
        type: "axis_offset--axis_offset",
        attributes: {
          offset_year: data?.offset_year,
          offset_average_price: data?.offset_average_price,
        },
        relationships: {
          author: {
            data: {
              type: "user--user",
              id: currentUser?.uuid,
            },
          },
          Offset_group: {
            data: {
              type: "axis_group--axis_group",
              id: currentUser?.group?.uuid,
            },
          },
        },
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiDeleteOffset = ({ uuid }) => {
  return ajax({
    method: "delete",
    url: `/jsonapi/axis_offset/${uuid}`,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const apiUpdateOffsets = (data) => {
  return ajax({
    method: "post",
    url: `/rest/entity/offset/update`,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
