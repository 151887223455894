import {
  Button,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  Typography,
  Divider,
  TextField as MuiTextField,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import MyTextField from "components/ui/inputs/MyTextField";
import { validateEmail } from "utils/validation";

const TextField = styled(MuiTextField)`
  // padding: 20px;
`;

export default function FormEnterEmail({ onSubmit, onLogin }) {
  const [email, setEmail] = React.useState("");

  const [errorEmail, setErrorEmail] = React.useState(null);

  const handleSubmit = (e) => {
    let flag = false;

    const emailValidation = validateEmail(email);
    if (emailValidation) {
      setErrorEmail(emailValidation);
      flag = true;
    }

    if (flag) return;

    onSubmit({ email });
  };

  const handleLogin = (e) => {
    onLogin(e);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorEmail(validateEmail(e.target.value));
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" fontSize="24px">
            Forgotten You Password?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" fontSize="16px">
            No problem. Enter your email address and if we recognize it, we'll
            send you a link to reset your password.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyTextField
                placeholder="Email Address"
                value={email}
                onChange={handleChangeEmail}
                errorMessage={errorEmail}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleSubmit}>
            Reset my password
          </Button>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Link onClick={handleLogin}>Return to login page</Link>
        </Grid>
      </Grid>
    </div>
  );
}
