import { Button, Grid, Stack, Typography } from "@mui/material";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPageConfig } from "redux/slices/common";
import { cloneDeep, set } from "lodash";
import { useSelector } from "react-redux";

const Title = styled(Typography)`
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  font-family: SofiaPro;
`;

export default function FormImportDataFiles5(props) {
  const { data, handleClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(
    (state) => state.common.currentPageConfig
  );

  const getSuccessMessage = (type) => {
    switch (type) {
      case "axis_transaction_import":
        return (
          <>
            <Grid item xs={12}>
              <Typography align="center" fontFamily="SofiaPro">
                Your Transaction data file has finished processing.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" fontFamily="SofiaPro" py={2}>
                Number of Transactions Uploaded:{" "}
                <Typography
                  component="span"
                  color="green2.main"
                  dangerouslySetInnerHTML={{
                    __html: `${data?.[0]?.import_data?.[0]?.item_count || 0}`,
                  }}
                />
              </Typography>
              <Typography align="center" fontFamily="SofiaPro" py={2}>
                Carbon Footprint of Import:{" "}
                <Typography
                  component="span"
                  color="green2.main"
                  dangerouslySetInnerHTML={{
                    __html: `${data?.[0]?.import_carbon_footprint?.[0]?.value || 0}`,
                  }}
                />
              </Typography>
              <Typography align="center" fontFamily="SofiaPro" py={2}>
                Number of My Tasks:{" "}
                <Typography
                  component="span"
                  color="green2.main"
                  dangerouslySetInnerHTML={{
                    __html: `${data?.[0]?.import_data?.[0]?.task_count || 0}`,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/my-tasks");
                    const filterClone = cloneDeep(currentPageConfig);
                    dispatch(
                      setCurrentPageConfig(
                        set(filterClone, `currentPage`, "my-tasks")
                      )
                    );
                    handleClose();
                  }}
                >
                  Take Me to My Tasks
                </Button>
              </Stack>
            </Grid>
          </>
        );
      case "axis_supplier_import":
        return (
          <>
            <Grid item xs={12}>
              <Typography align="center" fontFamily="SofiaPro">
                Your Supplier data file has finished processing.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" fontFamily="SofiaPro">
                Number of Suppliers Uploaded:{" "}
                <Typography
                  component="span"
                  color="green2.main"
                  dangerouslySetInnerHTML={{
                    __html: `${data?.[0]?.import_data?.[0]?.item_count || 0}`,
                  }}
                />
              </Typography>
              <Typography align="center" fontFamily="SofiaPro">
                Number of My Tasks:{" "}
                <Typography
                  component="span"
                  color="green2.main"
                  dangerouslySetInnerHTML={{
                    __html: `${data?.[0]?.import_data?.[0]?.task_count || 0}`,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/my-tasks");
                    const filterClone = cloneDeep(currentPageConfig);
                    dispatch(
                      setCurrentPageConfig(
                        set(filterClone, `currentPage`, "my-tasks")
                      )
                    );
                    handleClose();
                  }}
                >
                  Take Me to My Tasks
                </Button>
              </Stack>
            </Grid>
          </>
        );
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Title>Success!</Title>
      </Grid>
      {getSuccessMessage(data?.[0]?.import_type?.[0]?.value)}
    </Grid>
  );
}
