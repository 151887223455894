import { apiGetImportStatus } from "apis/common";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { getNextImportId } from "utils/asyncImport";

// Helper Functions.
const getImportStatusApi = () => {
  let id = getNextImportId();
  return `/rest/import-status?feed_id=${id}`;
};

// Services.
export const useApiGetImportStatus = (shouldFetch) => {
  return useSWR(
    shouldFetch ? () => getImportStatusApi() : null,
    async (url) => apiGetImportStatus(getImportStatusApi()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 30000,
    }
  );
};

export const getSstDropDownData = () => {
  const sstDropdown = useSelector((state) => state.common.sstDropdown);

  let subsidiary_id = null;
  let location_id = null;
  sstDropdown.map((item) => {
    if (item?.type == "subsidiaries") {
      subsidiary_id = item?.id;
    } else if (item?.type == "locations") {
      location_id = item?.id;
    }
  });

  return {
    subsidiary_id,
    location_id,
  };
};
