import {
    Button,
    Checkbox,
    Grid,
    Link,
    FormControlLabel,
    Typography,
    Divider,
    TextField as MuiTextField,
  } from "@mui/material";
  import React from "react";
  import styled from "@emotion/styled";
  import MyTextField from "components/ui/inputs/MyTextField";
  
  const TextField = styled(MuiTextField)`
    // padding: 20px;
  `;
  
  export default function FormPasswordUpdated({ onSubmit }) {
    const handleSubmit = (e) => {
      onSubmit(e);
    };
  
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" textAlign="center" fontSize="24px">
              Password Updated!
            </Typography>
          </Grid>
  
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleSubmit}>
              Login
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  